import { useState, useEffect } from 'react';
import './index.css';
import { useSnackbar } from 'notistack';
import Skeleton from '../Skeleton';

import { ReactComponent as Close } from '../../assets/close-icon.svg';
import { ReactComponent as Favorite } from '../../assets/marker-popup/favorite.svg';
import { ReactComponent as Delete } from '../../assets/delete-2.svg';

import Show from '../../utility/Show';
import MarkerList from '../Map/marker-list';
import AddressList from '../Map/address-list';

import {
  getOverallFeelScore,
  saveAddressToFavorites,
  lifeStyleFeaturesScores,
} from '../../services';

const MyFavorites = ({ state, dispatch, actions, className }) => {
  const {
    savedAddresses,
    favorites,
    currentAddress,
    isLoading,
    currentDistance,
    isStandalone,
  } = state;
  const [activeTab, setActiveTab] = useState('addresses');
  const [addressScores, setAddressScores] = useState([]);

  const [isMounted, setIsMounted] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const tabs = [
    { id: 'addresses', label: 'Addresses' },
    { id: 'establishments', label: 'Establishments' }
  ];

  const isTabActive = (tab) => {
    return tab === activeTab;
  }

  const handleTab = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  const closeFavorites = () => {
    dispatch({ type: 'SET_SHOW_FAVORITE_LIST', payload: false });
  };

  const fetchWalkIndexRatings = async (address, payload, signal) => {
    try {
      const score = await actions.fetchWalkIndexRatings(payload, 0, signal, true);
      return score[0];
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
      }
      throw error;
    }
  };

  const fetchLifeStyleScores = async (address, payload, signal) => {
    try {
      const score = await actions.fetchLifeStyleScores(payload, 0, signal, true);
      return score || {};
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
      }
      throw error;
    }
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    actions.getSavedAddresses();

    // dispatch({ type: 'SET_LOADING', payload: true });

    // const controller = new AbortController();
    // const signal = controller.signal;
    // fetchAddressesData(signal);
    // fetchMarkerDetails(favorites[propertyKey])

    // return () => {
    //   controller.abort();
    // };
  }, [isMounted]);

  const isListEmpty = () => {
    if (activeTab === 'addresses') {
      return savedAddresses.length === 0;
    } else {
      return favorites.length === 0;
    }
  };

  const removeFavorite = (address) => {
    saveAddressToFavorites(
      'remove',
      address,
      savedAddresses,
      dispatch,
      enqueueSnackbar,
    );

    const filteredScores = addressScores.filter((score) => {
      return score.property.toLowerCase() !== address.property.toLowerCase();
    });

    setAddressScores(filteredScores);
  };

  const getOverallGemScore = (currentScore) => {
    if (!currentScore) return 1;
    const scores = Object.values(lifeStyleFeaturesScores(
      currentScore,
      null,
      currentDistance,
      isStandalone,
    ));

    const counts = [0, 0, 0];
    scores.forEach((score) => {
      const roundedScore = Math.round(score);
      counts[roundedScore - 1]++;
    });

    if (counts[2] >= 1) return 3;
    if (counts[1] >= 1) return 2;
    return 1;
  };

  const renderAddresses = () => {
    return (
      <Show>
        <Show.When isTrue={isLoading || !isMounted}>
          <div className='loading-item'>
            <Skeleton count={1} size={14} width='348px' baseColor='#a9a9a9' />
            <Skeleton count={1} size={40} width='348px' baseColor='#a9a9a9' />
          </div>
          <div className='loading-item'>
            <Skeleton count={1} size={14} width='348px' baseColor='#a9a9a9' />
            <Skeleton count={1} size={40} width='348px' baseColor='#a9a9a9' />
          </div>
        </Show.When>
        <Show.Else>
          <Show>
            <Show.When isTrue={savedAddresses?.length > 0}>
              <AddressList addresses={savedAddresses} />
              {/* {savedAddresses.map((item, index) => (
                <div className='address-item'>
                  <div className='fav-address-details' onClick={() => handleAddressClick(item.property)}>
                    <div>{item.property}</div>
                    <div className='actions'>
                      <Delete onClick={() => removeFavorite(item)} />
                    </div>
                  </div>
                </div>
              ))} */}
            </Show.When>
            <Show.Else>
              No Data Found
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>
    )
  };

  const propertyKey = String(currentAddress.property);

  const renderEstablishments = () => {
    return (
      <Show>
        <Show.When isTrue={isLoading}>
          <div className='loading-item'>
            <Skeleton count={1} size={14} width='348px' baseColor='#a9a9a9' />
            <Skeleton count={1} size={40} width='348px' baseColor='#a9a9a9' />
          </div>
          <div className='loading-item'>
            <Skeleton count={1} size={14} width='348px' baseColor='#a9a9a9' />
            <Skeleton count={1} size={40} width='348px' baseColor='#a9a9a9' />
          </div>
        </Show.When>
        <Show.Else>
          <Show>
            <Show.When isTrue={favorites[propertyKey]?.length > 0}>
              <MarkerList markers={favorites[propertyKey]} isRemovable={true} />
            </Show.When>
            <Show.Else>
              No Data Found
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>
    )
  }

  return (
    <div className={`my-favorites-popup ${className || ''}`}>
      <div className="favorite-header">
        <div>
          <Favorite className='favorites-icon' /> My Favorite List
        </div>
        <Close className='close-icon' onClick={() => closeFavorites()} />
      </div>
      <div className="switch">
        {tabs.map((tab, index) => (
          <div
            className={`${isTabActive(tab.id) ? 'active' : ''}`}
            onClick={()=> handleTab(tab.id)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className={`list ${
        isListEmpty() ? 'empty' : ''
      }`}>
        {activeTab === 'addresses' ? renderAddresses() : renderEstablishments()}
      </div>
    </div>
  )
};

export default MyFavorites;