import { ReactComponent as Gem } from '../../assets/address-score/gem2.svg';
import { ReactComponent as Circle } from '../../assets/address-score/circle2.svg';
import { getActiveIcon } from '.';

import { ScoreIconV2 } from '../../utility/ScoreIcon';


const NSVersion3 = ({ isWalkIndex=false, score=1 }) => {
  return (
    <div className='ns-version3-wrapper grid grid-cols-3 justify-center w-full'>
      {[...Array(3)].map((_, index) => (
        <ScoreIconV2
          isWalkIndex={isWalkIndex}
          className={(index + 1) === score ? 'current-score' : ''}
          content={index + 1}
          index={index}
          showDesc={true}
          score={index + 1}
        />
        // <div className={`score-item ${
        //     !isWalkIndex ? 'gem' : ''
        //   } ${
        //     (index + 1) === score ? 'current-score' : ''
        //   }`}
        // >
        //   <div
        //   >
        //     {getIcon()}
        //     {getIcon('overlay-icon')}
        //     <span className='shadow-lg font-medium'>{index + 1}</span>
        //   </div>
        //   <div className='ns-version3-description text-center mt-2 text-sm'> { legend[index].label || 'Wow'} </div> 
        // </div>
      ))}
    </div>
  )
};

export default NSVersion3;
