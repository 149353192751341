import React, { useState, useContext, useRef } from 'react';
import { StoreContext } from '../../store';

import './index.css';
import { ReactComponent as WalkIndexIcon } from '../../assets/walkindex-icon.svg';
import { ReactComponent as GemIcon } from '../../assets/get-started/gem.svg';

import { ReactComponent as Feel1 } from '../../assets/score-value/feel-1.svg';
import { ReactComponent as Feel2 } from '../../assets/score-value/feel-2.svg';
import { ReactComponent as Feel3 } from '../../assets/score-value/feel-3.svg';
import { ReactComponent as Gem1 } from '../../assets/score-value/gem-1.svg';
import { ReactComponent as Gem2 } from '../../assets/score-value/gem-2.svg';
import { ReactComponent as Gem3 } from '../../assets/score-value/gem-3.svg';

import feature1 from '../../assets/get-started/feature1.png';
import feature2 from '../../assets/get-started/feature2.png';
import feature3 from '../../assets/get-started/feature3.png';

import GemMap from "../../assets/get-started/gem-map.png";
import FeelMap from "../../assets/get-started/feel-map.png";
import GemScore from "../../assets/get-started/gem-score.png";
import FeelScore from "../../assets/get-started/feel-score.png";
import MapFeatures1 from "../../assets/get-started/map-features-1.png";
import MapFeatures2 from "../../assets/get-started/map-features-2.png";

import { ReactComponent as WalkspanLogo } from '../../assets/walkspan-logo-2.svg';
import { ReactComponent as WalkspanLogo2 } from '../../assets/Walkspan-logo-whitefont.svg';
import { ReactComponent as Close } from '../../assets/cancel-white.svg';
import { ReactComponent as Close2 } from '../../assets/close-square.svg';

import { walkIndexFeatures, lifeStyleFeatures } from '../../constants';

import Slider from 'react-slick';

import ClickOutsideHandler from '../../utility/ClickOutsideHandler';
import Show from '../../utility/Show';

export const GetStarted = ({ setShowTutorial, version=1 }) => {
  const { actions, state } = useContext(StoreContext);
  const {
    isMobile,
    isStandalone,
    isDisclaimerOpen,
    showScoreGuide,
  } = state;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showLastSlide, setShowLastSlide] = useState(false);
  const sliderRef = useRef();

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => {
      console.log('index', index);
      setCurrentIndex(index);
    },
    className: 'get-started-slider',
  };

  const v2Settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentIndex(index),
    className: 'get-started-slider',
  };

  const feelLegends = [
    {
      score: 1,
      label: 'Meh Walkable',
      description: 'Scarce presence of features',
    },
    {
      score: 2,
      label: 'Yeah Walkable',
      description: 'Moderate presence of features',
    },
    {
      score: 3,
      label: 'Wow Walkable',
      description: 'High presence of features',
    },
  ];

  const feelIconScore = [Feel1, Feel2, Feel3];
  const gemIconScore = [Gem1, Gem2, Gem3];

  const walkindxRadius = (index) => {
    if (index === 0) {
      return '<25%';
    } else if (index === 1) {
      return '>25%<50%';
    } else {
      return '>50%';
    }
  };

  const essentialLegends = [
    {
      score: 1,
      label: 'Meh Walkable',
      description: '< 5 living essentials',
    },
    { score: 2, label: 'Yeah Walkable', description: '5-15 living essentials' },
    { score: 3, label: 'Wow Walkable', description: '> 15 living essentials' },
  ];

  const mapFeatures = [
    {
      label: 'View Map',
      description: 'map display of sidewalk features and gems',
      image1: feature1,
    },
    {
      label: 'Compare Addresses',
      description: 'multiple map display of neighborhoods',
      image1: feature2,
    },
    {
      label: 'Gem Filters/Priority Search',
      description: 'detailed breakdown of living essentials',
      image1: feature3,
    },
  ];

  const getIconColor = (color) => {
    const style = {
      '--color': color,
    };
    return style;
  };

  const hideContent = (index) => {
    if (currentIndex !== index && isMobile) return 'hide-content';
  };

  const featuresSummary = (index) => {
    return (
      <div className={`page-container ${hideContent(index)}`}>
        <Show.When isTrue={!showScoreGuide}>
          <h2 className='page-title'>
            Neighborhood Intelligence Features Summary
          </h2>
        </Show.When>
        <Show.When isTrue={!isStandalone}>
          <div className='feature-type-container'>
            <p className='type-title'>Feel Score</p>
            <Show.When isTrue={!showScoreGuide}>
              <p className='type-description'>
                Sensation while walking along a sidewalk with presence of
                nature, architecture, Vibrance, comfort and quiet.
              </p>
            </Show.When>
            <div className='legends-container'>
              {feelLegends.map((legend, index) => {
                const FeelIcon = feelIconScore[index];
                const className = index === 0
                  ? 'feel-one' :
                  index === 1
                  ? 'feel-two'
                  : '';
                return (
                  <div className='legend-container'>
                    <div className='score-wrapper'>
                      <div className='icon-wrapper'>
                        <WalkIndexIcon />
                        <FeelIcon className={`icon-score-value ${className}`} />
                        <h1>{legend.score}</h1>
                      </div>
                    </div>
                    <h3 className='score-label'>{legend.label}</h3>
                    <div
                      className='score-description'
                      data-border-style={(index + 1).toString()}
                      style={{ '--tab-color': '#000' }}
                    >
                      {legend.description}
                    </div>
                    <p className='score-radius'>{walkindxRadius(index)}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <Show.When isTrue={!showScoreGuide}>
            <div className='feature-divider'></div>
          </Show.When>
        </Show.When>

        <div className='feature-type-container'>
          <p className='type-title'>Gem Score</p>
          <Show.When isTrue={!showScoreGuide}>
            <p className='type-description'>
              Availability of living essentials like food, shops, services,
              transit, and leisure.
            </p>
          </Show.When>
          <div
            className={`legends-container ${
              isStandalone && 'standalone-legends-container'
            }`}
          >
            {essentialLegends.map((legend, index) => {
              const GemScoreIcon = gemIconScore[index];
              const className = index === 0
                ? 'gem-one' :
                index === 1
                ? 'gem-two' :
                index === 2
                ? 'gem-three':
                '';
              return (
                <div
                  className={`legend-container 
                                lifestyle-container 
                                ${
                                  isStandalone &&
                                  'standalone-lifestyle-container'
                                }`}
                >
                  <div className='score-wrapper lifestyle-score'>
                    <div className='icon-wrapper'>
                      <GemIcon />
                      <GemScoreIcon className={`icon-score-value ${className}`} />
                      <h1>{legend.score}</h1>
                    </div>
                  </div>
                  <h3 className='score-label'>{legend.label}</h3>
                  <div
                    className='score-description'
                    data-border-style={(index + 1).toString()}
                    style={{ '--tab-color': '#000' }}
                  >
                    {legend.description}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const WalkindxFeatures = (index) => {
    return (
      <div className={`features-container ${hideContent(index)}`}>
        <div className='features-header'>
          <h2 className='page-title'>What We Score - Neighborhood Feel</h2>
          <p className='type-description'>
            A sidewalk corridor experience of nature, architecture, Vibrance,
            comfort, and quiet at a {textWithNonBreakingHyphen('5-to-20')}{' '}
            minute circle from the property of interest. The higher the score of
            the circle, the better the corridors for walking.
          </p>
        </div>
        <div className='features'>
          {walkIndexFeatures.map((feature) => {
            return (
              <div
                className='feature-container'
                style={getIconColor(feature.color)}
              >
                {feature.svg}
                <p className='feature-label'>{feature.name}</p>
                <p className='feature-description'>{feature.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const Disclaimer = (index) => {
    return (
      <div>
        <div className='features-header'>
          <h2 className='page-title'>Disclaimer</h2>
          <div className='page-disclaimer'>
            <span>
              Information provided by <b>WALKSPAN</b> is made available without
              express or implied warranties of any kind and is subject to the
              following disclaimer:
            </span>

            <span>
              <b>WALKSPAN</b> makes no claims, promises, or guarantees about the
              absolute accuracy, completeness, or adequacy of the contents of
              its neighborhood intelligence platform and expressly disclaims for
              any errors, omissions, or other defects in, delays or
              interruptions in such data, or for any actions taken in reliance
              thereon.
            </span>

            <span>
              <b>WALKSPAN</b> may make changes to information at any time to
              add, update, or correct the information provided as it attempts to
              maintain the highest accuracy of content on its platform. Rating
              is based on objective measurement of tangible elements within the
              sidewalk as well as use of open data. The rating can change
              overtime as we are in constant effort to hear from individuals
              accessing this platform. Users will make their own determination
              on suitability of the information and data for their usage and
              intent.
            </span>

            <span>
              <b>WALKSPAN</b> shall not be held liable for any improper or
              incorrect use of the information described and/or contained herein
              and assumes no responsibility for anyone's use of the information.
              In no event will <b>WALKSPAN</b> be responsible for damages
              resulting from the use or reliance upon this information and data,
              including, but not limited to procurement of substitute goods or
              services; loss of use, data, or profits; or business interruption.
              Individuals using <b>WALKSPAN’s</b> map data, traffic, directions,
              and other content may find that actual conditions differ from the
              map results and content and in doing so should exercise
              independent judgment and use <b>WALKSPAN</b> at their own risk.
              Individuals are always responsible for their conduct and its
              consequences.
            </span>

            <h3> Privacy Policy</h3>

            <span>
              We respect your privacy and are committed to protecting your
              personal information. This privacy policy outlines how we collect,
              use, and safeguard your data when you interact with our services.
              Your information is treated with the utmost confidentiality and is
              not shared with third parties unless required by law or with your
              explicit consent. We utilize industry-standard security measures
              to protect your data. By using our services, you agree to the
              terms of this privacy policy.
            </span>
          </div>
        </div>
      </div>
    );
  };

  const textWithNonBreakingHyphen = (text) => {
    const nonBreakingHyphen = '\u2011';
    return text.replace(/-/g, nonBreakingHyphen);
  };

  const LifeStyleFeatures = (index) => {
    const filteredFeatures = lifeStyleFeatures.slice(1, -1);
    return (
      <div className={`features-container ${hideContent(index)}`}>
        <div className='features-header'>
          <h2 className='page-title'>What We Score - Neighborhood Gems</h2>
          <p className='type-description'>
            Living essentials like food, transit, shops, services, and leisure
            found within a 5-10-20 minute circle from the property. The more
            living essentials found the higher the score.
          </p>
        </div>
        <div className='features'>
          {filteredFeatures.map((feature) => {
            return (
              <div
                className='feature-container'
                style={getIconColor(feature.color)}
              >
                {feature.svg}
                <p className='feature-label'>{feature.name}</p>
                <p className='feature-description'>{feature.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderMapFeatures = (index) => {
    return (
      <div className={`map-features-container ${hideContent(index)}`}>
        <h2 className='page-title'>Advanced Features</h2>
        <div className='advanced-features-container'>
          {mapFeatures.map((feature) => {
            return (
              <div className='map-feature'>
                <div className='map-feature-images-container'>
                  <img
                    src={feature.image1}
                    alt='Image 1'
                    className='map-feature-image'
                  />
                </div>
                <div className='map-feature-details'>
                  <span className='map-feature-label'>{feature.label}</span>
                  <span className='map-feature-description'>
                    {feature.description}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const allSetSlide = (index) => {
    return (
      <div className={`all-set-container`}>
        <WalkspanLogo />
        <div className='all-set-slogan-container'>
          <h2>Your neighborhood, your streets, your story</h2>
          <p>
            Let <span className='walkspan-text'>Walkspan</span> guide your
            search.
          </p>
        </div>
        <button onClick={() => setShowTutorial(false)}>Get Started</button>
      </div>
    );
  };

  const renderSlider = () => {
    return (
      <Show>
        <Show.When isTrue={showScoreGuide}>{featuresSummary(0)}</Show.When>
        <Show.Else>
          <Slider {...settings}>
            {featuresSummary(0)}
            {!isStandalone && WalkindxFeatures(1)}
            {LifeStyleFeatures(2)}
            {renderMapFeatures(3)}
            {allSetSlide(4)}
          </Slider>
        </Show.Else>
      </Show>
    );
  };

  const renderLeftPanelContent = () => {
    return (
      <Show>
        <Show.When isTrue={currentIndex === 0}>
          <WalkspanLogo2 />
          <div>A Neighborhood Intelligence Platform</div>
        </Show.When>
        <Show.When isTrue={currentIndex === 1}>
          <img src={GemMap} alt="gem-map" />
        </Show.When>
        <Show.When isTrue={currentIndex === 2}>
          <img src={FeelMap} alt="feel-map" />
        </Show.When>
        <Show.When isTrue={currentIndex === 3}>
          <img src={GemScore} alt="gem-score" />
        </Show.When>
        <Show.When isTrue={currentIndex == 4}>
          <img src={FeelScore} alt="feel-score" />
        </Show.When>
        <Show.When isTrue={currentIndex === 5}>
          <img src={MapFeatures1} alt="map-feature" />
          <img src={MapFeatures2} alt="map-feature" />
        </Show.When>
      </Show>
    )
  }

  const leftPanelFooter = () => {
    return (
      <div className='footer'>
        <Show>
          <Show.When isTrue={currentIndex !== 0}>
            <WalkspanLogo2 />
          </Show.When>
        </Show>
        <div>{currentIndex + 1}/6</div>
      </div>
    )
  };

  const handleNext = () => {
    if (currentIndex !== 5) {
      sliderRef.current.slickNext()
    } else {
      setShowLastSlide(true);
    }
  }

  const rightPanelFooter = () => {
    return (
      <div className='footer'>
        <Show>
          <Show.When isTrue={currentIndex !== 0}>
            <div onClick={() => sliderRef.current.slickPrev()}>
              {'<< Previous'}
            </div>
          </Show.When>
        </Show>
        <div
          className='next-btn'
          onClick={() => handleNext()}
        >
          <Show>
            <Show.When isTrue={currentIndex === 0}>
              {'Continue >>'}
            </Show.When>
            <Show.Else>
              {'Next >>'}
            </Show.Else>
          </Show>
        </div>
      </div>
    )
  };

  const welcomeSlide = () => {
    return (
      <>
        <h4 className='title'>Welcome</h4>
        <div className='content'>
          Walkspan is an Ai-driven data and location intelligence platform based 
          in New York City for the real estate industry designed to seamlessly 
          integrate with property search engines. It shows buyers and renters not 
          only the property but the neighborhood they desire. The intelligence 
          platform operationalizes neighborhood search through its patent issued 
          single interface, scoring, mapping, reporting and recommendation system.
        </div>
      </>
    )
  };

  const gemSlide = () => {
    const filteredFeatures = lifeStyleFeatures.slice(1, -1);
    return (
      <>
        <h5 className='title'>What we score</h5>
        <h3 className='content-title'>Neighborhood Gems</h3>
        <div className='content'>
          Living essentials like food, transit, shops, services and leisure found 
          within a 5-10-20 minute circle from the property. 
          The more living essentials found the higher the score.
        </div>
        <div className='features'>
          {filteredFeatures.map((feature) => {
            return (
              <div
                className='feature-container'
                style={getIconColor(feature.color)}
              >
                {feature.svg}
                <p className='feature-label'>{feature.name}</p>
                <p className='feature-description'>{feature.description}</p>
              </div>
            );
          })}
        </div>
      </>
    )
  };

  const feelSlide = () => {
    return (
      <>
        <h5 className='title'>What we score</h5>
        <h3 className='content-title'>Neighborhood Feel</h3>
        <div className='content'>
          A sidewalk corridor experience of nature, architecture, vibrance, 
          comfort and quiet at a 5-10-20 minutes circle from the property of interest. 
          A higher circle score means the corridors are better for walking.
        </div>
        <div className='features'>
          {walkIndexFeatures.map((feature) => {
            return (
              <div
                className='feature-container'
                style={getIconColor(feature.color)}
              >
                {feature.svg}
                <p className='feature-label'>{feature.name}</p>
                <p className='feature-description'>{feature.description}</p>
              </div>
            );
          })}
        </div>
      </>
    )
  };

  const scoreLegends = (isWalkIndex) => {
    return [
      {
        label:'Meh',
        score: 1,
        desc: isWalkIndex ? 'No feature presence' : 'No gems',
        percent: '0%'
      },
      {
        label: 'Yeah',
        score: 2,
        desc: isWalkIndex ? 'Moderate feature presence' : 'Moderate presence',
        percent: '<50%'
      },
      {
        label: 'Wow',
        score: 3,
        desc: isWalkIndex ? 'High feature presence' : 'High presence',
        percent: '>50%'
      },
    ];
  };

  const HWSLegend = (isWalkIndex) => {
    return (
      <div className='score-legend-wrapper'>
        {scoreLegends(isWalkIndex).map((legend) => {
          return (
            <div className='score-legend-item'>
              <div className='score-legend-label'><b>{legend.label}</b></div>
              <div className='score-legend-score'>{legend.score}</div>
              <div className='score-legend-desc'>{legend.desc}</div>
              <div className='text-center'>{legend.percent}</div>
            </div>
          )
        })}
      </div>
    )
  }

  const HWSGemSlide = () => {
    return (
      <>
        <h5 className='title'>How we score</h5>
        <h3 className='content-title'>Gem Score</h3>
        <div className='content'>
          Availability of living essentials like food, shops, services, transit and leisure
        </div>
        {HWSLegend()}
      </>
    )
  };

  const HWSFeelSlide = () => {
    return (
      <>
        <h5 className='title'>How we score</h5>
        <h3 className='content-title'>Feel Score</h3>
        <div className='content'>
          Sensation while walking along a sidewalk with  presence of nature, architecture, sociability, comfort and quiet.
        </div>
        {HWSLegend(true)}
      </>
    )
  };

  const advancedFeaturesSlide = () => {
    return (
      <>
        <h5 className='title'>Advanced Features</h5>
        <div className='advanced-features-wrapper'>
          <div>
            <h3 className='content-title'>Detail Map</h3>
            <div className='content'>
              Map display of sidewalk features and gems
            </div>
          </div>
          <div>
            <h3 className='content-title'>Compare Neighborhoods</h3>
            <div className='content'>
              Multiple map displays of neighborhoods
            </div>
          </div>
          <div>
            <h3 className='content-title'>Gems Filter</h3>
            <div className='content'>
              Detailed breakdown of gems
            </div>
          </div>
          <div>
            <h3 className='content-title'>Quick Search</h3>
            <div className='content'>
              Specific gem search within 0.25, 0.5 or 1 mi radius
            </div>
          </div>
        </div>
      </>
    )
  };

  const v2FinalSlide = () => {
    return (
      <>
        <WalkspanLogo />
        <div>
          <div>
            Your neighborhood, your streets, your story
          </div>
          <div>
            Let <span>Walkspan</span> guide your search
          </div>
        </div>
        <div className='get-started-btn' onClick={() => setShowTutorial(false)}>
          Get Started
        </div>
      </>
    )
  }


  return (
    <div
      className={`get-started-container ${
        showScoreGuide && 'score-guide-container'
      }`}
      style={actions.getBrandingColor()}>
      <ClickOutsideHandler
        className={`get-started-card ${
          version === 2  ? 'v2-card' : ''
        }`}
        onOutsideClick={() => setShowTutorial(false)}
      >
        <Show.When isTrue={!showLastSlide}>
          <Show>
            <Show.When isTrue={version === 1}>
              <Close className='close-icon' onClick={() => setShowTutorial(false)} />
            </Show.When>
            <Show.Else>
              <Close2 className='close-icon' onClick={() => setShowTutorial(false)} />
            </Show.Else>
          </Show>
        </Show.When>
        <Show>
          <Show.When isTrue={isDisclaimerOpen}>{Disclaimer(4)}</Show.When>
          <Show.Else>
            <Show>
              <Show.When isTrue={version === 1}>
                {renderSlider()}
              </Show.When>
              <Show.Else>
                <div className={`v2-slide ${
                    showLastSlide ? 'last' : ''
                  }`}
                >
                  <Show>
                    <Show.When isTrue={!showLastSlide}>
                      <div className='left-panel'>
                        <div className='left-content'>
                          {renderLeftPanelContent()}
                        </div>
                        <Show.When isTrue={!isMobile}>
                          {leftPanelFooter()}
                        </Show.When>
                      </div>
                      <div className='right-panel'>
                        <Slider ref={sliderRef} {...v2Settings}>
                          {welcomeSlide()}
                          {gemSlide()}
                          {feelSlide()}
                          {HWSGemSlide()}
                          {HWSFeelSlide()}
                          {advancedFeaturesSlide()}
                        </Slider>
                        {rightPanelFooter()}
                      </div>
                    </Show.When>
                    <Show.Else>
                      {v2FinalSlide()}
                    </Show.Else>
                  </Show>
                </div>
              </Show.Else>
            </Show>
          </Show.Else>
        </Show>
      </ClickOutsideHandler>
    </div>
  );
};
