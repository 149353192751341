import React, { useState, useEffect, useContext, useRef } from 'react';
import { StoreContext } from '../store';
import { fetchPlaceDetails } from '../api';

import { ReactComponent as Action } from '../assets/add-circle.svg';
import { ReactComponent as Minus } from '../assets/minus-circle.svg';

import Show from './Show';
import ClickOutsideHandler from './ClickOutsideHandler';
import { normalizeAddress } from '../helper';

export const Autocomplete = ({
  className,
  placeholder,
  index,
  onClick = null,
  value,
  isClearable = false,
  onClear=null,
  icon = null,
}) => {
  const { state, dispatch, actions } = useContext(StoreContext);
  const {
    currentView,
    map,
    isMobile,
    showComparePopup,
    compareLifeStyleAddress,
    currentCity,
    currentTab,
  } = state;
  const inputRef = useRef();
  const autoCompleteRef = useRef();

  const [predictions, setPredictions] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [placeID, setPlaceID] = useState(null);
  const [isDisable, setDisable] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const isModern = currentView === 'Modern';

  useEffect(() => {
    if (isMobile) {
      if (value !== null) {
        setInputValue(value);
      }
    } else {
      if (value && value !== null) {
        setInputValue(value);
      }
    }
  }, [value]);

  useEffect(() => {
    if (placeID && placeID !== null) {
      fetchPlaceDetails(placeID)
        .then((details) => {
          const payload = {
            placeID: details?.place_id,
            property: details?.formatted_address,
            latitude: details?.geometry?.location?.lat(),
            longitude: details?.geometry?.location?.lng(),
            neighborhood: actions.getNeighborhood(details),
          };
          if (onClick) {
            if (index !== undefined) {
              onClick(payload, index);
            } else {
              onClick(payload);
            }
          }
        })
        .catch((error) => {
          console.error('Error:', error.message);
        });
    }
  }, [placeID]);

  const searchAddress = () => {
    const autocompleteService = new window.google.maps.places.AutocompleteService();
    if (autocompleteService && inputRef.current) {
      const inputText = inputRef.current.value;
      const city = currentCity ? `${currentCity}, ` : '';

      autocompleteService.getPlacePredictions(
        {
          input: city + inputText,
          types: ['geocode'],
          componentRestrictions: { country: "us" }
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            // Filter predictions by checking if any description exists within property or vice versa
            const filteredPredictions = predictions.filter(prediction => 
              !compareLifeStyleAddress.some(existingAddress => 
                existingAddress?.placeID === prediction?.place_id
              )
            );

            setPredictions(filteredPredictions);
            setShowResult(true);
          } else {
            setPredictions([]);
          }
        }
      );
    }
  };
  
  const handleSearchChange = (event) => {
    if (event.target.value === '' || event.target.value === null) {
      setPredictions([]);
    } else {
      searchAddress();
    }
    setInputValue(event.target.value);
  };

  const handleShowResult = () => {
    const hasResult = predictions.length > 0;
    setShowResult(hasResult);
  };

  const handleFocus = () => {
    inputRef.current.select();
    handleShowResult()
  };

  const handleBlur = () => {
    if (inputRef.current) {
      inputRef.current.selectionStart = 0;
      inputRef.current.selectionEnd = 0;
    }
    setShowResult(false);
  };

  const addressClick = async (address) => {
    if (onClick) { 
      setPlaceID(address.place_id);
      setInputValue(address.description);
    } else {
      actions.sendDataToGoogleAnalytics('used_address_search', {});
      dispatch({ type: 'SET_MAP_TAB', payload: currentTab === 'walkindex' ? 'Nature' : 'All' });
      dispatch({ type: 'SET_SHOW_SEARCH_ADDRESS', payload: false });
      dispatch({ type: 'SET_LOADING', payload: true });
      await actions.fetchAddressData(address.description);
    }

    handleOutsiteClick();
  };

  const handleAdd = () => {
    setDisable(false);
    setIsFocused(true);
  }

  const handleClear = () => {
    setInputValue('');
    setIsFocused(false);
    setDisable(false);
    if (onClear) {
      onClear(index)
    }
  };

  const handleOutsiteClick = () => {
    setShowResult(false);
    setIsFocused(false);
    if (value) {
      setInputValue(value);
    } else {
      setDisable(true);
    }
  };

  return (
    <ClickOutsideHandler
      className={`autocomplete-wrapper ${className}`}
      onOutsideClick={handleOutsiteClick}
      // onBlur={() => handleBlur()}
    >
      {icon && React.createElement(icon, { className: "w-[15px] h-[15px] mr-2" })}
      <input
        ref={inputRef}
        value={inputValue}
        id='search-address'
        onChange={(e) => handleSearchChange(e)}
        onFocus={() => handleFocus()}
        placeholder={placeholder}
        autoComplete='off'
        // disabled={isDisable && inputValue?.length === 0}
      />
      {/* <Show.When
        isTrue={
          isModern &&
          isDisable &&
          !isFocused &&
          inputValue === '' &&
          value === undefined
        }
      >
        <Action className='add-icon' onClick={() => handleAdd()} />
      </Show.When> */}
      <Show.When
        isTrue={
          isModern &&
          isClearable &&
          !isFocused &&
          inputValue !== ''
        }
      >
        <Show>
          <Show.When isTrue={showComparePopup}>
            <Minus onClick={() => handleClear()} />
          </Show.When>
          <Show.Else>
            <Action className='clear-icon' onClick={() => handleClear()} />
          </Show.Else>
        </Show>
      </Show.When>
      <Show.When isTrue={predictions.length !== 0 && showResult}>
        <div
          className={`prediction-container ${
            currentView === 'Modern' && 'modern-prediction-container'
          }`}
        >
          {predictions.map((prediction) => (
            <div
              className='prediction'
              key={prediction.place_id}
              onClick={() => addressClick(prediction)}
            >
              {prediction.description}
            </div>
          ))}
        </div>
      </Show.When>
    </ClickOutsideHandler>
  );
};
