
const GraphMapToggle = ({ state, dispatch }) => {

  const { showCompareGraph } = state;

  const handleToggle = (value) => {
    dispatch({ type: 'SET_SHOW_COMPARE_GRAPH', payload: value });
  };

  return (
    <div className="gm-toggle-wrapper">
      <button
        className={`${showCompareGraph ? 'active' : ''}`}
        onClick={() => handleToggle(true)}
      >
        Graph
      </button>
      <button
        className={`${!showCompareGraph ? 'active' : ''}`}
        onClick={() => handleToggle(false)}
      >
        Map
      </button>
    </div>
  )
};

export default GraphMapToggle;