import { useState, useEffect } from "react";
import './index.css';

import { ReactComponent as Close } from '../../assets/close-icon.svg';
import { ReactComponent as Recent } from '../../assets/recently-viewed.svg';

import Skeleton from '../Skeleton';
import { ScoreIcon } from "../../utility/ScoreIcon";
import Show from "../../utility/Show";
import AddressList from "../Map/address-list";

import { getOverallFeelScore, lifeStyleFeaturesScores } from "../../services";

const RecentHistory = ({ state, dispatch, actions, className }) => {
  const {
    recentlyViewedAddresses,
    currentDistance,
    isStandalone,
    isLoading,
  } = state;
  const [isMounted, setIsMounted] = useState(false);
  const [addressScores, setAddressScores] = useState([]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    actions.getRecentlyViewed();

    // const controller = new AbortController();
    // const signal = controller.signal;
    // fetchAddressesData(signal);

    // return () => {
    //   controller.abort();
    // };
  }, [isMounted]);

  const isListEmpty = () => {
    return recentlyViewedAddresses.length === 0;
  };

  const closeRecentHistory = () => {
    dispatch({ type: 'SET_SHOW_RECENT_HISTORY', payload: false });
  };

  const getOverallGemScore = (currentScore) => {
    if (!currentScore) return 1;
    const scores = Object.values(lifeStyleFeaturesScores(
      currentScore,
      null,
      currentDistance,
      isStandalone,
    ));

    const counts = [0, 0, 0];
    scores.forEach((score) => {
      const roundedScore = Math.round(score);
      counts[roundedScore - 1]++;
    });

    if (counts[2] >= 1) return 3;
    if (counts[1] >= 1) return 2;
    return 1;
  };

  const fetchWalkIndexRatings = async (address, payload, signal) => {
    try {
      const score = await actions.fetchWalkIndexRatings(payload, 0, signal, true);
      return score[0];
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
      }
      throw error;
    }
  };

  const fetchLifeStyleScores = async (address, payload, signal) => {
    try {
      const score = await actions.fetchLifeStyleScores(payload, 0, signal, true);
      return score || {};
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
      }
      throw error;
    }
  };

  const fetchAddressesData = async (signal) => {
    try {
      const scores = await Promise.all(
        recentlyViewedAddresses.map(async (address) => {
          const payload = {
            latitude: address.latitude,
            longitude: address.longitude,
          };
          const feelScore = await fetchWalkIndexRatings(address.property, payload, signal);
          const gemScore = await fetchLifeStyleScores(address.property, payload, signal);

          return {
            property: address.property,
            feelScore: feelScore,
            gemScore: gemScore,
          };
        })
      );

      setAddressScores(scores);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const renderAddresses = () => {
    return (
      <Show>
        <Show.When isTrue={isLoading || !isMounted}>
          <div className='loading-item'>
            <Skeleton count={1} size={14} width='348px' baseColor='#a9a9a9' />
            <Skeleton count={1} size={40} width='348px' baseColor='#a9a9a9' />
          </div>
          <div className='loading-item'>
            <Skeleton count={1} size={14} width='348px' baseColor='#a9a9a9' />
            <Skeleton count={1} size={40} width='348px' baseColor='#a9a9a9' />
          </div>
        </Show.When>
        <Show.Else>
          <Show>
            <Show.When isTrue={recentlyViewedAddresses?.length > 0}>
              <AddressList addresses={recentlyViewedAddresses} />
            </Show.When>
            <Show.Else>
              No Data Found
            </Show.Else>
          </Show>
        </Show.Else>
      </Show>
    )
  };

  return (
    <div className={`recent-history-popup ${className || ''}`}>
      <div className="history-header">
        <div>
          <Recent className='recent-icon' /> Recent History
        </div>
        <Close className="close-icon" onClick={() => closeRecentHistory()} />
      </div>
      <div className={`${isListEmpty() ? 'empty' : ''}`}>
        {renderAddresses()}
      </div>
    </div>
  );
};

export default RecentHistory;