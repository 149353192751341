import React, { useContext } from 'react'
import { StoreContext } from '../../store'
import { ReactComponent as LegendScore1 } from '../../assets/legend-score-1.svg'
import { ReactComponent as LegendScore2 } from '../../assets/legend-score-2.svg'
import { ReactComponent as LegendScore3 } from '../../assets/legend-score-3.svg'
import { ReactComponent as Legend1 } from '../../assets/legend-score1.svg'
import { ReactComponent as Legend2 } from '../../assets/legend-score2.svg'
import { ReactComponent as Legend3 } from '../../assets/legend-score3.svg'
import { getTypeDescription, getTabColor, legendWalkIndexDescription, getDescription } from '../../services'
import Show from '../../utility/Show'
import '../NeighborhoodScore/index.css'
import { ScoreIcon } from "../../utility/ScoreIcon";
import { walkIndexFeatures } from "../../constants";

const MapTypeLegends = ({version = 1}) => {
  const { state } = useContext(StoreContext)
  const { currentTab, mapTab, currentDistance, isStandalone, currentView } = state

  const isWalkIndex = currentTab === 'walkindex'
  const isModern = currentView === 'Modern';

  const legend = [
    { label: 'Meh', value: 1 },
    { label: 'Yeah', value: 2 },
    { label: 'Wow', value: 3 },
  ]

  // const getEstablishmentType = () => {
  //   if (mapTab === 'All') return 'Gems'
  //   if (mapTab === 'Food') return 'Restaurants'
  //   if (mapTab === 'Transit' || mapTab === 'Leisure') return `${mapTab}s`
  //   return mapTab
  // }

  const getFeelLineLegend = (score) => {
    const lines = [LegendScore1, LegendScore2, LegendScore3]
    const IconComponent = lines[score - 1] || null
    return IconComponent ? <IconComponent /> : null
  }

  const getFeelLineLegend2 = (score) => {
    const lines = [Legend1, Legend2, Legend3]
    const IconComponent = lines[score - 1] || null
    return IconComponent ? <IconComponent /> : null
  }

  const getLegendLabel = (index) => {
    return getTypeDescription(
      isWalkIndex ? 'walkindex' : 'lifestyle',
      mapTab?.toLowerCase(),
      index,
      currentDistance,
      isStandalone,
      true
    )
  }

  const getLegendDescription = (index) => {
    const scores = index;
    const finalType = mapTab?.toLowerCase() === 'vibrance' ? 'activities' : mapTab?.toLowerCase();
  
    const getDescription = (descriptionSource) => {
      return descriptionSource?.[finalType]?.[(scores || 1) - 1];
    };
  
    return getDescription(legendWalkIndexDescription)
  };

  const renderLegends = () => {
    return (
      <div
        className={`neighborhood-score-legend-container ${
          isModern && 'modern-neighborhood-score-legend-container'
        }`}
      >
        {legend.map((item, index) => (
          <div
            className="neighborhood-legend-label-container"
            key={`neighborhood-legend-${index}`}
          >
            <div
              className={`small-score-icon ${
                !isWalkIndex && !isModern && 'small-score-lifestyle'
              } ${isModern && 'modern-small-score-icon'} ${
                isModern && !isWalkIndex && 'modern-small-score-lifestyle'
              }`}
            >
              <ScoreIcon
                height="23px"
                width="auto"
                withBg={true}
                fontSize="12px"
                score={item.value?.toString()}
                content={item.value?.toString()}
              />
            </div>
            <p>{item.label}</p>
          </div>
        ))}
      </div>
    )
  }

  const renderMapTypeLegends = () => {
    return (
      <div className="map-type-legends">
        {[1, 2, 3].map((index) => (
          <div
            key={index}
            className="map-type-legend"
            data-border-style={index}
            style={getTabColor(isWalkIndex, mapTab)}
          >
            <Show.When isTrue={isWalkIndex}>
              {getFeelLineLegend(index)}
            </Show.When>
            <span className='feel-line-label'>{getLegendLabel(index)}</span>
          </div>
        ))}
      </div>
    )
  }

  const renderVersion2GemsLegend = () => {
    return (
      <div className='flex gap-x-6 items-center py-4 w-full'>
        <span class='w-[200px] text-left pl-4'>Gems</span>
        <div className="grid grid-cols-3 gap-x-4 w-full">
          {[1, 2, 3].map((index) => (
            <div
              key={index}
              data-border-style={index}
              style={getTabColor(isWalkIndex, mapTab)}
            >
              {getLegendLabel(index)}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const renderVersion2FeelLegend = () => {
    return (
      <div className='flex flex-col gap-y-4 py-4 text-sm'>
        <div className='flex gap-x-6 items-start w-full'>
          <span class='w-[230px]'>{getDescription(isWalkIndex, mapTab)}</span>
          <div className="grid grid-cols-3 gap-x-4 w-full">
            {[1, 2, 3].map((index) => (
              <div
                key={index}
                className="text-center text-sm"
                data-border-style={index}
                style={getTabColor(isWalkIndex, mapTab)}
              >
                {getLegendDescription(index)}
              </div>
            ))}
          </div>
        </div>
        <div className='flex gap-x-6 items-center w-full'>
          <span class='w-[230px]'>Sidewalk Score</span>
          <div className="map-type-legends grid grid-cols-3 gap-x-4 w-full">
            {[1, 2, 3].map((index) => (
              <div
                key={index}
                className="map-type-legend"
                data-border-style={index}
                style={getTabColor(isWalkIndex, mapTab)}
              >
                {getFeelLineLegend2(index)}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  const renderLengedType = () => {
    return version === 1 ? (
      <>
        {renderLegends()}
        {renderMapTypeLegends()}
      </>
    ) : (
      isWalkIndex ? renderVersion2FeelLegend() : renderVersion2GemsLegend()
    )
  }

  return (
    <div className='legends-container'>
      {renderLengedType()}
    </div>
  )
}

export default MapTypeLegends
