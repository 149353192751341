import React, { useState, useEffect, useCallback } from 'react';
import './index.css';
import { ReactComponent as AddressMarker } from '../../assets/active-marker.svg';
import FoodMarker from '../../assets/cluster/food.svg';
import ShopMarker from '../../assets/cluster/shops.svg';
import ServiceMarker from '../../assets/cluster/services.svg';
import TransitMarker from '../../assets/cluster/transit.svg';
import EntertainmentMarker from '../../assets/cluster/entertainment.svg';

import Show from '../../utility/Show';
import MobileScoreContainer from './mobile-score-container';
import { WalkingLoader } from '../Loaders';

import { findColor, getZoomMini, getArea, convertMarkers } from '../../services';
import { GoogleMap, OverlayView, Circle, Marker } from '@react-google-maps/api';

import useGemMarkers from '../../hooks/useGemMarkers';

const MiniMap = ({ property, state, dispatch, actions }) => {
  const {
    currentDistance,
    isStandalone,
    currentView,
    currentTab,
    isGoogleMapsLoaded,
    isMobile,
    isiPhone,
    mapTab,
    currentAddress,
    currentPresence,
    isMapLoading,
    isLoading,
    showDashboard,
    token,
    apiKey,
    userDetails,
    LSEmail,
  } = state;
  const [isMounted, setIsMounted] = useState(false);
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(18);
  const [mapType, setMapType] = useState('satellite');
  const [markers, setMarkers] = useState([]);

  const isWalkIndex = currentTab === 'walkindex';

  const controller = new AbortController();
  const signal = controller.signal;

  const {
    data: gemMarkers,
    error: gemMarkersError,
    isLoading: gemMarkersLoading,
  } = useGemMarkers(
    currentAddress?.latitude,
    currentAddress?.longitude,
    'all',
    token,
    apiKey,
    getArea(currentDistance),
    signal
  );

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!gemMarkers || !Array.isArray(gemMarkers)) return;

    const markers = convertMarkers(gemMarkers);
    setMarkers(markers);
    actions.handleFetchedMarkers(markers);
  }, [gemMarkers]);

  useEffect(() => {
    if (map) {
      generateMapWalkIndex(map);
    }
  }, [map]);

  useEffect(() => {
    setZoom(getZoomMini(currentDistance));

    if (isWalkIndex) {
      generateMapWalkIndex(map);
    }
  }, [currentDistance]);

  useEffect(() => {
    if (!isMounted) return;
    // clearMapTypes();
    if (isWalkIndex) {
      generateMapWalkIndex(map);
    } else {
      clearMapTypes();
    }
  }, [currentTab]);

  const center = {
    lat: parseFloat(property?.latitude),
    lng: parseFloat(property?.longitude),
  };

  const getDefaultTab = () => {
    return currentTab === 'walkindex' ? 'Nature' : 'All';
  };

  const getGeoserverBackdropLink = (bbox, viewParams) => {
    let geoserver = 'geoserver/walkspan/wms?';
    geoserver += '&REQUEST=GetMap';
    geoserver += '&SERVICE=WMS';
    geoserver += '&VERSION=1.1.1';
    geoserver += '&LAYERS=walkspan:walkspan_backdrop';
    geoserver += '&FORMAT=image/png';
    geoserver += '&BGCOLOR=0xFFFFFF';
    geoserver += '&TRANSPARENT=TRUE';
    geoserver += '&SRS=EPSG:' + '4326';
    geoserver += '&BBOX=' + bbox;
    geoserver += '&WIDTH=256';
    geoserver += '&HEIGHT=256';
    geoserver += '&VIEWPARAMS=' + viewParams;
    return geoserver;
  };

  const getGeoserverLink = (layer, bbox, type, viewParams) => {
    let geoserver = 'geoserver/walkspan/wms?';
    geoserver += '&REQUEST=GetMap';
    geoserver += '&SERVICE=WMS';
    geoserver += '&VERSION=1.1.1';
    geoserver += '&LAYERS=walkspan:' + layer; //walkspan_circle
    geoserver += '&FORMAT=image/png';
    geoserver += '&BGCOLOR=0xFFFFFF';
    geoserver += '&TRANSPARENT=TRUE';
    geoserver += '&SRS=EPSG:' + '4326';
    geoserver += '&BBOX=' + bbox;
    geoserver += '&WIDTH=256';
    geoserver += '&HEIGHT=256';
    geoserver += '&STYLES=' + type;
    geoserver += '&VIEWPARAMS=' + viewParams;
    return geoserver;
  };

  const clearMapTypes = () => {
    if (map) map.overlayMapTypes.clear();
  };

  const generateMapWalkIndex = (map1) => {
    // dispatch({ type: 'SET_LOADING', payload: true });
    const currentMap = map1;

    if (currentMap) {
      const tab = mapTab;
      currentMap.overlayMapTypes.clear();
      const radiusMap = {
        'quarter-mile': 25,
        'half-mile': 50,
        'one-mile': 100,
      };
      let viewParams = '_radius:' + (radiusMap[getArea(currentDistance)] || 10000);

      viewParams += `;_lat:${(currentAddress?.latitude * 10000).toFixed()};_lng:${Math.abs(
        currentAddress?.longitude * 10000
      ).toFixed()}`;

      const getFilter = (viewParams += currentPresence
        ? `;_walk_indx_metric:${
          getDefaultTab().toLowerCase()
          };_score_filter:${currentPresence}`
        : '');

      const backdrop = new window.google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
          const proj = currentMap.getProjection();
          const zfactor = Math.pow(2, zoom);
          // Get Long Lat coordinates
          const top = proj.fromPointToLatLng(
            new window.google.maps.Point(
              (coord.x * 256) / zfactor,
              (coord.y * 256) / zfactor
            )
          );
          const bot = proj.fromPointToLatLng(
            new window.google.maps.Point(
              ((coord.x + 1) * 256) / zfactor,
              ((coord.y + 1) * 256) / zfactor
            )
          );
          // Create the Bounding box string
          const bbox = `${top.lng()},${bot.lat()},${bot.lng()},${top.lat()}`;
          // Build WMS URL
          const geoserver = getGeoserverBackdropLink(bbox, viewParams);

          return `${process.env.REACT_APP_API_URL}/geoserver/?geoserver=${encodeURIComponent(
            geoserver
          )}
          &email=${userDetails?.email || LSEmail}&api_key=${userDetails?.api_key}`;
        },
        tileSize: new window.google.maps.Size(256, 256),
        isPng: true,
        name: 'WalkspanBackdropLayer',
      });

      currentMap.overlayMapTypes.push(backdrop);

      if (isWalkIndex) {
        const layer = new window.google.maps.ImageMapType({
          getTileUrl: (coord, zoom) => {
            const zfactor = Math.pow(2, zoom);
            const proj = currentMap.getProjection();

            // Get Long Lat coordinates
            const bot = proj.fromPointToLatLng(
              new window.google.maps.Point(
                ((coord.x + 1) * 256) / zfactor,
                ((coord.y + 1) * 256) / zfactor
              )
            );
            const top = proj.fromPointToLatLng(
              new window.google.maps.Point(
                (coord.x * 256) / zfactor,
                (coord.y * 256) / zfactor
              )
            );
            // Create the Bounding box string
            const bbox = `${top.lng()},${bot.lat()},${bot.lng()},${top.lat()}`;
            // Build WMS URL
            const geoserver = getGeoserverLink(
              'walkspan_circle',
              bbox,
              `${getDefaultTab().toLowerCase()}WalkspanV3`,
              getFilter
            );
            const url = `${process.env.REACT_APP_API_URL}/geoserver/?action=Geoserver&geoserver=${encodeURIComponent(
              geoserver
            )}&email=${userDetails?.email || LSEmail}&api_key=${userDetails?.api_key}`;

            return url;
          },
          tileSize: new window.google.maps.Size(256, 256),
          maxZoom: 9,
          minZoom: 0,

          isPng: true,
          name: 'WalkspanLayer',
        });

        if (layer) {
          currentMap.overlayMapTypes.push(layer);
        }
      }
      // dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const onLoad = useCallback(function callback(map1) {
    map1.setMapTypeId('satellite');
    setMapType('satellite');
    setMap(map1);
    generateMapWalkIndex(map1);
  }, []);

  const getMarkerIcon = (type) => {
    switch (type) {
      case 'food':
        return FoodMarker;
      case 'shops':
        return ShopMarker;
      case 'services':
        return ServiceMarker;
      case 'transit':
        return TransitMarker;
      case 'leisure':
        return EntertainmentMarker;
      default:
        return EntertainmentMarker;
    }
  };

  const customMarkerIcon = (type) => {
    const getMarkerSize = [14, 18];
    return {
      url: getMarkerIcon(type),
      scaledSize: new window.google.maps.Size(
        getMarkerSize[0],
        getMarkerSize[1]
      ),
    };
  };

  const getMapHeight = () => {
    if ((isStandalone || currentView === 'Modern') && !isMobile) {
      return '255px';
    }
    if (isiPhone) {
      return 'calc(100dvh - 380px)';
    }
    if (isMobile && currentView === 'Modern') {
      return 'calc(100dvh - 380px)';
    } else {
      return '220px';
    }
  }

  return (
    <div
      className='maps-container'
      style={{
        width: '100%',
        height: getMapHeight(),
        position: 'relative',
      }}
    >
      <Show.When isTrue={isMobile}>
        <MobileScoreContainer />
      </Show.When>
      <Show.When isTrue={property && isGoogleMapsLoaded}>
        <GoogleMap
          mapContainerStyle={{ height: '100%', width: '100%' }}
          defaultCenter={center}
          zoom={zoom}
          center={center}
          onLoad={onLoad}
          options={{
            zoomControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            styles: [
              {
                featureType: 'all',
                elementType: 'all',
              },
              {
                featureType: 'poi',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'transit',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'water',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'administrative',
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'landscape',
                elementType: 'geometry',
                stylers: [
                  { visibility: 'on' },
                  { hue: '#007bff' },
                  { saturation: -20 },
                  { lightness: -10 },
                ],
              },
            ],
          }}
        >
          <Show.When isTrue={!isMobile && currentAddress?.sublocality}>
            <div className='map-address-sublocality'>
              {currentAddress?.sublocality}
            </div>
          </Show.When>
          <Show.When isTrue={currentDistance >= 3}>
            <Circle
              center={center}
              radius={1609.34}
              options={{
                strokeColor: findColor(currentTab, getDefaultTab()),
                strokeOpacity: 1,
                strokeWeight: 3,
                fillColor: 'transparent',
              }}
            />
          </Show.When>

          <Show.When isTrue={currentDistance >= 2}>
            <Circle
              center={center}
              radius={804.68}
              options={{
                strokeColor: findColor(currentTab, getDefaultTab()),
                strokeOpacity: 1,
                strokeWeight: 3,
                fillColor: 'transparent',
              }}
            />
          </Show.When>

          <Show.When isTrue={currentDistance >= 1}>
            <Circle
              center={center}
              radius={402.34}
              options={{
                strokeColor: findColor(currentTab, getDefaultTab()),
                strokeOpacity: 1,
                strokeWeight: 3,
                fillColor: 'transparent',
              }}
            />
          </Show.When>

          <Show.When isTrue={!isWalkIndex && markers?.length > 0}>
            {markers?.map((marker, index) => (
              <Marker
                id={`cluster-marker-${index}`}
                key={`cluster-marker-${index}`}
                position={{
                  lat: parseFloat(marker.lat),
                  lng: parseFloat(marker.lng),
                }}
                icon={customMarkerIcon(marker.type)}
              ></Marker>
            ))}
          </Show.When>

          <OverlayView
            position={center}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => ({
              x: -width - 44 / 2,
              y: -height - 100 / 2,
            })}
          >
            <div
              className={`active-marker ${
                mapType === 'satellite' ? 'white-active-marker' : ''
              }`}
            >
              <AddressMarker />
            </div>
          </OverlayView>
        </GoogleMap>
      </Show.When>
      <Show.When isTrue={gemMarkersLoading || isLoading}>
        <WalkingLoader />
      </Show.When>
    </div>
  );
};

export default MiniMap;
