import React, { useContext } from 'react';
import { StoreContext } from '../../store';
import { ReactComponent as PlusIcon } from '../../assets/zoom-in.svg';
import { ReactComponent as MinusIcon } from '../../assets/zoom-out.svg';
import { ReactComponent as Target } from '../../assets/target.svg';
import { ReactComponent as Satellite } from '../../assets/satellite.svg';
import { ReactComponent as Map } from '../../assets/map.svg';
import { ReactComponent as QuickSearch } from '../../assets/quick-search.svg';
import { ReactComponent as Filter } from '../../assets/filter-2.svg';
import { Tooltip } from 'react-tooltip';
import Show from "../../utility/Show";
import './index.css';
import { getZoomLifeStyle } from '../../services';

const MapControls = (index = 1) => {
  const { state, dispatch, actions } = useContext(StoreContext);
  const { currentTab, zoom, mapType, showCompareLifeStyle,  isMobile, isLoading, isFullScreenMap, isEstablishmentSearchOpen, mapTab, showFilter, currentDistance } = state;

  const isWalkIndex = currentTab === 'walkindex';

  const zoomValue = getZoomLifeStyle(currentDistance)

  const handleZoomClick = (value) => {
    actions.sendDataToGoogleAnalytics('used_zoom_btn', {});
    if (value >= zoomValue) {
      dispatch({ type: 'SET_CURRENT_ZOOM', payload: value });
    }
  };

  const goToCenter = () => {
    actions.setMapCenter();
    actions.sendDataToGoogleAnalytics('recenter_map', {});
  };

  const toggleMapType = () => {
    const type = mapType === 'roadmap' ? 'satellite' : 'roadmap';
    actions.sendDataToGoogleAnalytics('changed_map_type', { map_type: type });
    dispatch({ type: 'SET_CURRENT_MAPTYPE', payload: type });
  };

  const openFilter = () => {
    if (isEstablishmentSearchOpen) {
      dispatch({ type: 'SET_IS_ESTABLISHMENT_SEARCH_OPEN', payload: false });
    }

    dispatch({ type: 'SET_SHOW_FILTERS', payload: !showFilter });
  }

  return (
    <div>
      {!isLoading && !isEstablishmentSearchOpen && (
        <div className={`map-tools ${showCompareLifeStyle && 'modern-lifestyle-map-controls'}`}>
          <div className={`map-right-controls`}>
            <div className={`map-control-wrapper modern-map-control-wrapper`}>

              <Show.When isTrue={mapTab !== 'Favorites' && !isWalkIndex && !isMobile}>
                <div
                  className={`filter-btn ${
                    showFilter ? 'open' : ''
                  }`}
                  data-tooltip-id="filter"
                  onClick={() => openFilter()}
                >
                  <Filter />
                </div>
              </Show.When>

              <Show.When isTrue={!isMobile}>
                <div className='map-control-btn' data-tooltip-id="center-map" onClick={goToCenter}>
                  <Target />
                </div>
              </Show.When>
              <Show.When isTrue={!isMobile}>
                <div className='zoom-control-wrapper'>
                  <div className='map-control-btn' data-tooltip-id="zoom-in-map" onClick={() => handleZoomClick(zoom + 0.5)}>
                    <PlusIcon />
                  </div>
                  <div className='map-control-btn' data-tooltip-id="zoom-out-map" onClick={() => handleZoomClick(zoom - 0.5)}>
                    <MinusIcon />
                  </div>
                </div>
              </Show.When>
              {!isMobile && (
                <>
                  <div data-tooltip-id={`change-view${index ? '-' + index : ''}`} className="map-control-btn change-view-btn" onClick={toggleMapType}>
                    {mapType === 'roadmap' ? <Map /> : <Satellite />}
                  </div>
                </>
              )}
              {!isMobile && (
                <>
                  <Tooltip id={`street-view${index ? '-' + index : ''}`} place='left'>Street View</Tooltip>
                  <Tooltip id='filter' place='left'>Filter</Tooltip>
                  <Tooltip id='expand-map' place='top'>Expand Map</Tooltip>
                  <Tooltip id='zoom-in-map' place='top'>Zoom In</Tooltip>
                  <Tooltip id='zoom-out-map' place='top'>Zoom Out</Tooltip>
                  <Tooltip id='center-map' place='top'>Center Map</Tooltip>
                  <Tooltip id='establishments' place={showCompareLifeStyle ? 'left' : 'top'}>Establishments</Tooltip>
                  <Tooltip id='change-location' place='top'>Change Location</Tooltip>
                  <Tooltip id={`change-view${index ? '-' + index : ''}`} place={showCompareLifeStyle ? 'left' : 'top'}>{mapType === 'roadmap' ? 'Map' : 'Satellite'} View</Tooltip>
                  <Tooltip id='fullscreen-map' place='top'>{isFullScreenMap ? 'Minimize' : 'Fullscreen'} Map</Tooltip>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapControls;
