import { useState, useRef } from 'react';
import './index.css';

import Show from '../../utility/Show';
import FormError from '../../utility/FormError';

import { ReactComponent as WalkspanLogo } from '../../assets/walkspan-logo-2.svg';

import { isValidEmail, setLSData } from '../../services';

const EmailAuthGate = ({ state, actions, dispatch }) => {
  const { userDetails, errorMessage } = state;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const formDataObj = Object.fromEntries(formData.entries());
    const email = formDataObj['email'];
    if (!isValidEmail(email)) {
      dispatch({
        type: 'SET_ERROR_MESSAGE',
        payload: 'Please enter a valid email address.'
      });
      return;
    }

    setLSData('auth-email', email);
    dispatch({ type: 'SET_LS_EMAIL', payload: email });
    setIsSubmitted(true);
    dispatch({ type: 'SET_ERROR_MESSAGE', payload: '' });
    dispatch({ type: 'SET_SHOW_EMAIL_AUTH_GATE', payload: false });
  }

  return (
    <div className="email-gate-container">
      <div className="email-gate-card">
        <WalkspanLogo />
        <div>To continue using our platform, please enter your email</div>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Show.When isTrue={errorMessage && !isSubmitted}>
            <FormError error={errorMessage} textColor='#f14c4c' />
          </Show.When>
          <input
            type='text'
            name='email'
            placeholder='Enter your email'
            // onChange={(e) => setEmail(e.target.value)}
          />
          <button type='submit'>Submit</button>
        </form>
      </div>
    </div>
  )
}

export default EmailAuthGate;