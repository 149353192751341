import { useContext, useState, useEffect } from 'react';
import { StoreContext } from '../../store';
import './index.css';

import { ReactComponent as QuickSearch } from '../../assets/quick-search.svg';
import { ReactComponent as Close } from '../../assets/close-icon.svg';
import { ReactComponent as Search } from '../../assets/search.svg';

import { getEstablishmentColor } from '../../services';

import Show from '../../utility/Show';
import MarkerList from '../Map/marker-list';

const MapQuickSearch = () => {
  const { state, dispatch, actions } = useContext(StoreContext);
  const { quickSearchMarkers, originalMarkers, showCompareLifeStyle, currentMarker, isEstablishmentSearchOpen, categoryFilters } = state;
  const [inputValue, setInputValue] = useState(categoryFilters[0] || '' );
  const getMarker = isEstablishmentSearchOpen ? quickSearchMarkers.markers : originalMarkers;
  const [filteredMarkers, setFilteredMarkers] = useState(getMarker);

  useEffect(() => {
    handleChange(categoryFilters[0])
  }, [])

  const handleChange = (searchText) => {
    if (!showCompareLifeStyle && currentMarker[0]) {
      dispatch({
        type: 'SET_CURRENT_MARKER',
        payload: { markerIndex: 0, markerData: null },
      });
    }

    if (searchText === '') {
      setFilteredMarkers([]);
      setInputValue('')
      return;
    }

    setInputValue(searchText)
    
    const newMarkers = getMarker?.filter((marker) => {
      const markerCategory = marker.category;
      const markerName = marker.name?.toLowerCase();
      const category = markerCategory
        ? markerCategory.toLowerCase()
        : 'others';
      return searchText !== '' &&
      (markerName.includes(searchText.toLowerCase()) ||
      category.includes(searchText.toLowerCase()));
    });
    setFilteredMarkers(newMarkers);
    dispatch({ type: 'SEARCH_CATEGORY', payload: searchText });
  };

  const closeMapQS = () => {
    dispatch({ type: 'SET_SHOW_MAP_QS', payload: false });
    dispatch({ type: 'SEARCH_CATEGORY', payload: '' });
    // actions.setMapCenter();
  };

  return (
    <div className="map-qs max-h-[600px]">
      <div className='qs-header'>
        <div>
          <QuickSearch /> Quick Search
        </div>
        <Show.When isTrue={!isEstablishmentSearchOpen}>
          <Close onClick={closeMapQS} />
        </Show.When>
      </div>
      <div className='input-wrapper'>
        <input
          type="text"
          placeholder="Search for an establishment ..."
          value={inputValue}
          onChange={(e) => handleChange(e.target.value)}
          disabled={isEstablishmentSearchOpen}
        />
        <Search />
      </div>
      <Show.When isTrue={inputValue !== ''}>
        {/* <div className='num-result'>
          Results:
          <Show.When isTrue={filteredMarkers.length > 0}>
            <span>{filteredMarkers.length} found ...</span>
          </Show.When>
        </div> */}
        <MarkerList markers={filteredMarkers} />
      </Show.When>
    </div>
  )
}

export default MapQuickSearch;