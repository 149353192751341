import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { StoreContext } from '../../store';
import './lifestyleEssentials.css';
import HouseIcon from '../../assets/score/house.svg';
import { ReactComponent as High } from '../../assets/high.svg';
import { ReactComponent as Moderate } from '../../assets/moderate.svg';
import { ReactComponent as Low } from '../../assets/low.svg';
import Empty from '../../assets/empty.svg';
import Diamond from '../../assets/diamond.svg';
import { ReactComponent as Favorite } from '../../assets/marker-popup/favorite.svg';
import { getLimit, saveAddressToFavorites, isObjectEmpty } from '../../services';

import Show from '../../utility/Show';

import { Autocomplete } from '../../utility/Autocomplete';

export const LifeStyleTab = ({ title = 'LIFESTYLE SCORE' }) => {
  const { actions, dispatch, state } = useContext(StoreContext);
  const { enqueueSnackbar } = useSnackbar();

  const {
    compareLifeStyleAddress,
    scoreProperties,
    currentDistance,
    isStandalone,
    currentView,
    savedAddresses,
  } = state;

  const isModern = currentView === 'Modern';

  const renderScore = (length) => {
    const limit = getLimit(currentDistance);
    if (length >= 0 && length <= limit[0]) {
      return <Low />;
    } else if (length <= limit[1]) {
      return <Moderate />;
    } else {
      return <High />;
    }
  };

  const renderLegends = () => {
    return (
      <div className='property-location-item'>
        <div className='scores-indicator'>
          <div className='value-container'>
            <div className={`value-bar ${isModern && 'sharp-border'}`}>
              <div className={`meh-value ${isModern && 'sharp-border'}`}></div>
            </div>
            <span>1</span>
          </div>
          <div className='value-container'>
            <div className={`value-bar ${isModern && 'sharp-border'}`}>
              <div className={`yay-value ${isModern && 'sharp-border'}`}></div>
            </div>
            <span>2</span>
          </div>
          <div className='value-container'>
            <div className={`value-bar ${isModern && 'sharp-border'}`}>
              <div className={`wow-value ${isModern && 'sharp-border'}`}></div>
            </div>
            <span>3</span>
          </div>
        </div>
      </div>
    );
  };

  const handleClick = async (payload, index) => {
    dispatch({
      type: 'SET_COMPARE_MAPS_SLIDER_INDEX',
      payload: index
    });
    dispatch({
      type: 'UPDATE_LIFESTYLE_COMPARE_ADDRESS',
      payload: { item: index, address: payload },
    });
    await actions.fetchLifeStyleScores(payload, index);
    await actions.fetchWalkIndexRatings(payload, index);
  };

  const handleClear = (index) => {
    console.log('clear', index);
    dispatch({
      type: 'UPDATE_LIFESTYLE_COMPARE_ADDRESS',
      payload: { item: index, address: null },
    });
    dispatch({
      type: 'SET_COMPARE_MAPS',
      payload: { index, object: {} }
    });
    if (index !== 0) {
      dispatch({
        type: 'UPDATE_COMPARE',
        payload: { number: index, length: 0 },
      });
      dispatch({
        type: 'SET_WALKINDEX_SCORES',
        payload: {
          walkindxScoreData: {},
          walkindxScoreIndex: index,
        }
      });
    }
  };

  const isAddressSaved = (currentAddress) => {
    return savedAddresses?.some(
      (address) => address?.property === currentAddress?.property
    );
  };

  const handleSaveAddressToFavorites = (address) => {
    saveAddressToFavorites(
      isAddressSaved(address) ? 'remove' : 'save',
      address,
      savedAddresses,
      dispatch,
      enqueueSnackbar,
    );
  };

  const renderCompare = () => {
    return (
      <div
        className={`compare-location-container ${
          isModern && 'modern-compare-location-container'
        }`}
        style={actions.getBrandingColor()}
      >
        <Show.When isTrue={!isModern}>{renderLegends()}</Show.When>
        <div className='property-locations-container'>
          {compareLifeStyleAddress.map((item, index) => {
            return (
              <div
                className={`property-location-item ${
                  isModern && 'modern-property-location-item'
                }`}
              >
                <Show>
                  <Show.When isTrue={isModern}>
                    <div>A{index + 1}</div>
                    <Autocomplete
                      className='lifestyle-search-address-input'
                      placeholder={`Enter Address ${index + 1}`}
                      value={item?.property}
                      index={index}
                      onClick={handleClick}
                      onClear={handleClear}
                      isClearable={index !== 0}
                    />
                    <Show.When isTrue={item && !isObjectEmpty(item)}>
                      <Favorite
                        className={`add-to-favorites-icon ${
                          isAddressSaved(item) ? 'added-to-favorites' : ''
                        }`}
                        onClick={() => handleSaveAddressToFavorites(item)}
                      />
                    </Show.When>
                    {/* <div className='lifestyle-search-address-input'>
                      <input
                        // ref={inputRef}
                        id='search-address'
                        // onChange={(e) => handleSearchChange(e)}
                        placeholder={`Enter Address ${index+1}`}
                      />
                      <Add />
                    </div> */}
                  </Show.When>
                  <Show.Else>
                    <div className='house-icon-container'>
                      <img
                        className='house-icon'
                        src={HouseIcon}
                        alt='House Icon'
                      />
                      <span
                        className={`house-number ${
                          isStandalone && 'standalone-house-number'
                        }`}
                      >
                        {index + 1}
                      </span>
                    </div>
                    <div
                      className={`address-container ${
                        item?.property ? '' : 'empty-address-bg'
                      } ${isModern && 'sharp-border'}`}
                    >
                      <Show>
                        <Show.When
                          isTrue={item?.property && item?.property !== null}
                        >
                          <div>
                            <p className='property-address'>{item?.property}</p>
                            {/* {!isStandalone && <p className='property-city'>New York, NY, USA</p>} */}
                          </div>
                          {renderScore(scoreProperties[index])}
                        </Show.When>

                        <Show.Else>
                          <img src={Diamond} alt='Empty Property' />
                        </Show.Else>
                      </Show>
                    </div>
                  </Show.Else>
                </Show>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='lifestyle-block'>
        <Show.When isTrue={!isModern}>
          <h5 className='lifestyle-text'>{title}</h5>
        </Show.When>
        {renderCompare()}
      </div>
      {/* <RatingsBar /> */}
    </div>
  );
};
