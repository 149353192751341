import axios from 'axios';
import httpClient from './interceptors';

const walkspan_api = process.env.REACT_APP_API_URL;
const x_api_key = process.env.X_API_KEY;

export const login = async (data) => {
  try {
    const payload = {
      email: 'tmttan03@gmail.com',
      password: 'Admin12345+',
    };
    const finalPayload = data || payload;
    const response = await axios.post(
      `${walkspan_api}/users/login/`,
      finalPayload
    );

    return response;

    // if (response.data.Error) {
    //   return response.data;
    // } else {
    //   const accessToken = response.data.token;
    //   return accessToken;
    // }
  } catch (error) {
    console.error(error);
    return error.response.data;
    // if (error.response) {
    //   console.error(error);
    //   return error.response
    // } else {
    //   console.error('An error occurred:', error.message);
    //   return { error: '50x' }; // General 50x error indicator
    // }
  }
};

export const logout = async () => {
  try {
    const response = await axios.post(`${walkspan_api}/users/logout/`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getProfilebyEmail = async (email) => {
  try {
    const url = `${walkspan_api}/users/get-profile-by-email/?email=${encodeURIComponent(email)}`;
    const response = await httpClient.get(url);

    return response.data;
  } catch (error) {
    console.error(error);
    return error.response.data;
  }
};

export const verifyAPIKey = async (token, data) => {
  try {
    const url = `${walkspan_api}/users/verify-api-key/`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const forgotPassword = async (data) => {
  try {
    const url = `${walkspan_api}/users/forget-password/`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const confirmForgotPassword = async (data) => {
  try {
    const url = `${walkspan_api}/users/reset-password/${data.code}/`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const signup = async (data) => {
  try {
    const url = `${walkspan_api}/users/signup/`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    console.error(error);
    return error.response
  }
};

export const resendSignup = async (data) => {
  try {
    const url = `${walkspan_api}/users/resend-confirm-code/`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const confirmSignup = async (data) => {
  try {
    const url = `${walkspan_api}/users/activate/${data.code}/`;
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    console.error(error);
    return error.response
  }
};

export const getLifeStyleEssentials = async (
  lat,
  lng,
  type,
  area = 'quarter-mile',
  email,
) => {
  try {
    const getType = type === 'leisure' ? 'entertainment-fitness' : type;
    const url = `${walkspan_api}/lifestyle-essentials/?areatype=${area}&lat=${lat}&lng=${lng}&type=${getType}&email=${email}`;
    const response = await httpClient.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    // if (error.response) {
    //   if (error.response.status === 401) {
    //     return error.response.data;
    //   }
    // } else {
    //   console.error('An error occurred:', error.message);
    //   return { error: '50x' }; // General 50x error indicator
    // }
  }
};

export const getLifeStyleScores = async (
  lat,
  lng,
  area = 'quarter-mile',
  email,
  signal,
) => {
  try {
    const url = `${walkspan_api}/lifestyle-essentials/count/?areatype=${area}&lat=${lat}&lng=${lng}&email=${email}`;

    const response = await httpClient.get(url, { signal });

    // const response = await axios.get(url, {signal});
    return response.data.reduce((acc, item) => {
      acc[item.type] = item.count;
      return acc;
    }, {});
  } catch (error) {
    console.error(error);
    if (error.response) {
      if (error.response.status === 401) {
        return error.response.data;
      }
    } else {
      console.error('An error occurred:', error.message);
      return { error: '50x' }; // General 50x error indicator
    }
  }
};

export const getRatings = async (
  lat,
  lng,
  area,
  email,
  signal,
) => {
  try {

    // /api/walkspan/get-ratings/?areatype=quarter-mile&lat=40.81713517954816&lng=-73.95964182716274
    const url = `${walkspan_api}/walkspan/get-ratings/?areatype=${area}&lat=${lat}&lng=${lng}&email=${email}`;
    // const response = await httpClient.get(url, {signal});

    const response = await httpClient.get(url, { signal });

    return response.data;
  } catch (error) {
    console.error(error);
    if (error.response) {
      if (error.response.status === 401) {
        return error.response.data;
      }
    } else {
      console.error('An error occurred:', error.message);
      return { error: '50x' }; // General 50x error indicator
    }
  }
};

export const getNeighborhoodScore = async (
  city = 'New York',
  state = 'NY',
  borough = '',
  email,
) => {
  try {
    const url = `${walkspan_api}/neighborhoods/all/?city=${city}&state=${state}&borough=${borough}&email=${email}`;
    const response = await httpClient.get(url);

    return response.data;
  } catch (error) {
    console.error(error);
    if (error.response && error.response.status === 401) {
      // const newToken = await login();
      // return await getNeighborhoodScore(city, state, borough, newToken);
    } else {
      return null;
    }
  }
};

export const fetchYelpRating = async (data) => {
  const { name, lat, lng, email } = data;
  const url = `${walkspan_api}/lifestyle-essentials/yelp-rating/?name=${name}&lat=${lat}&lng=${lng}&email=${email}`

  try {
    const response = await httpClient.get(url);
    return response.data?.Data?.[0];
  } catch (error) {
    console.error('Error fetching yelp rating:', error);
  }
}


export const fetchGoogleRating = async (data, email) => {
  const url = `${walkspan_api}/lifestyle-essentials/update-google-rating/?email=${email}`
  try {
    const response = await httpClient.post(url, data);
    return response;
  } catch (error) {
    console.error('Error fetching google rating:', error);
  }
}

export const logAPIKeyUsage = async (data) => {
  const url = `${walkspan_api}/users/log-api-key-usage/`
  try {
    const response = await httpClient.post(url, data);
    return response;
  } catch (error) {
    console.error('Error logging API Key', error);
  }
};

export const getPlaceByLatLng = async (latitude, longitude) => {
  const apiKey = 'AIzaSyAmcGjLChegfkj9yJZBMKhlk18ckavo3HI';

  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.status === 'OK') {
      return data.results[0];
    } else {
      console.error('Error fetching address:', data.status);
    }
  } catch (error) {
    console.error('Error fetching address:', error);
  }
};

export const fetchPlaceId = async (place, token) => {
  try {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      place
    )}&key=AIzaSyAmcGjLChegfkj9yJZBMKhlk18ckavo3HI`;
    const response = await axios.get(url);

    const { results } = response.data;

    if (results && results.length > 0) {
      return results[0];
    } else {
      throw new Error('Place ID not found.');
    }
  } catch (error) {
    throw new Error('Error fetching place ID.');
  }
};

export const fetchPlaceDetails = async (placeId) => {
  // Error Undefined PlacesService
  return new Promise((resolve, reject) => {
    if (!window.google || !window.google.maps || !window.google.maps.Map) {
      reject(new Error('Google Maps API is not loaded.'));
      return;
    }

    const map = new window.google.maps.Map(document.createElement('div'));

    const service = new window.google.maps.places.PlacesService(map);
    service.getDetails(
      {
        placeId: placeId,
        fields: ['ALL'],
      },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(place); // Resolve the promise with place details
        } else {
          reject(new Error('Place details request failed.'));
        }
      }
    );
  });
};
