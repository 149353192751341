import { useQuery, useQueryClient } from 'react-query';
import { useContext, useState } from 'react';
import { StoreContext } from '../store';
import axios from 'axios';
import { login } from '../api';
import httpClient from '../interceptors';

const walkspan_api = process.env.REACT_APP_API_URL;
const getLifeStyleEssentials = async (
  lat,
  lng,
  type,
  area = 'quarter-mile',
  email,
) => {
  try {
    const getType = type === 'leisure' ? 'entertainment-fitness' : type;

    const url = `${walkspan_api}/lifestyle-essentials/?areatype=${area}&lat=${lat}&lng=${lng}&type=${getType}&email=${email}`;
    const response = await httpClient.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    if (error.response) {
      if (error.response.status === 401) {
        return error.response.data;
      }
    } else {
      console.error('An error occurred:', error.message);
      return { error: '50x' }; // General 50x error indicator
    }
  }
};

const useGemMarkers = (lat, lng, type, token, apiKey, area, signal, mapTab, serviceRef, mapCenter) => {
  const queryClient = useQueryClient();
  const { dispatch, state } = useContext(StoreContext);
  const { userDetails, LSEmail } = state;
  const [isRefreshingToken, setIsRefreshingToken] = useState(false);
  
  return useQuery(
    ['lifestyleEssentials', lat, lng, type, area],
    async () => {
      const data = await getLifeStyleEssentials(lat, lng, type, area, userDetails?.email || LSEmail);
      if (data.Error === 'Unauthorized' && !isRefreshingToken) {
        setIsRefreshingToken(true);
        const newToken = await login();
        dispatch({ type: 'SET_TOKEN', payload: newToken });
        setIsRefreshingToken(false);

        const newData = await getLifeStyleEssentials(lat, lng, type, newToken, apiKey, area, signal);
        queryClient.setQueryData(
          ['lifestyleEssentials', lat, lng, type, area],
          newData
        );
        return newData;
      }
      return data;
    },
    {
      staleTime: 60000, // 1 minute
      cacheTime: 300000, // 5 minutes
      enabled: !!lat && !!lng && !!type, // Only run if these are available
    }
  );
};

const fetchInsights = async (mapCenter, type) => {
  const insightsUrl = "https://areainsights.googleapis.com/v1:computeInsights";
  const apiKey = "AIzaSyAmcGjLChegfkj9yJZBMKhlk18ckavo3HI";

  const insightsRequest = {
    insights: ["INSIGHT_COUNT", "INSIGHT_PLACES"],
    filter: {
      locationFilter: {
        circle: {
          latLng: {
            latitude: mapCenter.lat,
            longitude: mapCenter.lng,
          },
          radius: 400, // Search within 5km radius
        },
      },
      "typeFilter": { "includedTypes": "restaurant" }
    },
  };

  try {
    const response = await fetch(insightsUrl, {
      method: "POST",
      headers: {
        "X-Goog-Api-Key": apiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(insightsRequest),
    });

    if (!response.ok) {
      throw new Error(`Insights API error: ${response.statusText}`);
    }

    const data = await response.json();
    console.log("Insights Data:", data);
    return data.results || []; // Adjust this based on the actual response structure
  } catch (error) {
    console.error("Error fetching Insights:", error);
    return [];
  }
};

const handleUnifiedSearch = async (serviceRef, type, mapCenter) => {
  if (!serviceRef.current) {
    console.error("PlacesService is not initialized.");
    return [];
  }

  const nearbyRequest = {
    location: mapCenter,
    radius: 5000, // Search within 5km radius
    type, // For Nearby Search
  };

  const textRequest = {
    query: type, // For Text Search
    location: mapCenter,
    radius: 5000, // Search within 5km radius
  };

  const fetchNearbySearch = () => {
    return new Promise((resolve, reject) => {
      let results = [];
      const handleResults = (res, status, pagination) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          results = [...results, ...res];
          if (pagination && pagination.hasNextPage) {
            console.log("Fetching next page for Nearby Search...");
            setTimeout(() => pagination.nextPage(), 2000);
          } else {
            resolve(results);
          }
        } else {
          console.error("Nearby Search error:", status);
          reject(status);
        }
      };
      serviceRef.current.nearbySearch(nearbyRequest, handleResults);
    });
  };

  const fetchTextSearch = () => {
    return new Promise((resolve, reject) => {
      let results = [];
      const handleResults = (res, status, pagination) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          results = [...results, ...res];
          if (pagination && pagination.hasNextPage) {
            console.log("Fetching next page for Text Search...");
            setTimeout(() => pagination.nextPage(), 2000);
          } else {
            resolve(results);
          }
        } else {
          console.error("Text Search error:", status);
          reject(status);
        }
      };
      serviceRef.current.textSearch(textRequest, handleResults);
    });
  };

  try {
    const [nearbyResults, textResults] = await Promise.all([
      fetchNearbySearch(),
      fetchTextSearch(),
    ]);
    const unifiedResults = [...nearbyResults, ...textResults];
    console.log("Unified Results:", unifiedResults);
    return unifiedResults;
  } catch (error) {
    console.error("Error during search:", error);
    return [];
  }
};

export default useGemMarkers;
