import { useState, useEffect, useContext, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { StoreContext } from '../../store';
import './index.css';

import { ReactComponent as MobileBack } from '../../assets/landing-page/back-button.svg';
import { ReactComponent as Dots } from '../../assets/dots-horizontal.svg';
import { ReactComponent as Close } from '../../assets/cancel-white.svg';
import { ReactComponent as Action } from '../../assets/add-circle.svg';
import { ReactComponent as Menu } from '../../assets/burger-menu.svg';
import { ReactComponent as Favorite } from '../../assets/marker-popup/favorite.svg';

import ClickOutsideHandler from '../../utility/ClickOutsideHandler';
import Show from '../../utility/Show';
import { Autocomplete } from '../../utility/Autocomplete';

import {
  shareAddress,
  saveAddressToFavorites,
  isObjectEmpty,
  areObjectPropertiesNull
} from '../../services';

const MapMobileHeader = () => {
  const { state, dispatch, actions } = useContext(StoreContext);
  const {
    showDashboard,
    previousPage,
    showCompareLifeStyle,
    currentAddress,
    isEstablishmentSearchOpen,
    isAddressInNYC,
    currentTab,
    isGoogleMapsLoaded,
    isLoading,
    showMapMobileControls,
    savedAddresses,
    showRatingFilter,
    showCombineSearch,
    showFilter,
    showComparePopup,
    currentView,
    selectedMap,
    compareLifeStyleAddress,
    currentWalkindxType,
    currentLifestyleType,
    isMobileSidebarOpen,
    currentMarker,
    showCompareGraph,
  } = state;

  const [inputValue, setInputValue] = useState('');
  const [disableSearchAddress, setDisableSearchAddress] = useState(true);
  const [searchAddressValue, setSearchAddressValue] = useState(
    compareLifeStyleAddress[selectedMap]?.property
  );
  const [hideFeel, setHideFeel] = useState(false);
  const inputRef = useRef(null);
  const autoCompleteRef = useRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const isWalkIndex = currentTab === 'walkindex';
  const isModern = currentView === 'modern';

  useEffect(() => {
    if (!currentAddress) return;

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      const payload = { latitude: currentAddress.latitude, longitude: currentAddress.longitude };
      try {
        const feelScore = await actions.fetchLifeStyleScores(payload, selectedMap, signal, true);
        const gemScore = await actions.fetchWalkIndexRatings(payload, selectedMap, signal, true);

        if (!isAddressInNYC || (areObjectPropertiesNull(feelScore[0]) && isObjectEmpty(gemScore))) {
          setHideFeel(true);
          dispatch({ type: 'SET_CURRENT_TAB', payload: 'lifestyle' });
        } else {
          setHideFeel(false);
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching data:', error);
        }
      } finally {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    };

    // fetchData();
    return () => {
      controller.abort();
    };
  }, [isAddressInNYC]);

  const handleBack = () => {
    dispatch({ type: 'SET_SELECTED_MAP', payload: 0 });
    dispatch({ type: 'SET_COMPARE_MAPS_SLIDER_INDEX', payload: 0 });

    if (!isEstablishmentSearchOpen) {
      const distance = isAddressInNYC ? '1' : '3';
      dispatch({ type: 'SET_CURRENT_DISTANCE', payload: distance });
      dispatch({ type: 'SET_IS_ESTABLISHMENT_SEARCH_OPEN', payload: false });
      dispatch({ type: 'RESET_CATEGORY', payload: [''] });
      dispatch({
        type: 'SET_ESTABLISHMENT_SEARCH_RESULT',
        payload: {},
      });
    }

    if (previousPage?.length > 0) {
      actions.filterPreviousPage();
      dispatch({ type: 'SET_SHOW_FILTERS', payload: false });
      dispatch({ type: 'RESET_CATEGORY', payload: [''] });
      dispatch({ type: 'SET_RATES', payload: [] });
    } else {
      if (showDashboard) {
        dispatch({ type: 'SET_SHOW_SEARCH_ADDRESS', payload: true });
      } else {
        dispatch({ type: 'SET_SHOW_DASHBOARD', payload: true });
        handleComponents();
      }
    }
  };

  const handleComponents = () => {
    dispatch({ type: 'SET_DESKTOP_SIDEBAR', payload: false });

    if (showCombineSearch) {
      dispatch({ type: 'SET_SHOW_COMBINE_SEARCH', payload: false });
    }

    if (showFilter) {
      dispatch({ type: 'SET_SHOW_FILTERS', payload: false });
    }

    if (showCompareLifeStyle) {
      dispatch({ type: 'SET_COMPARE_LIFESTYLE', payload: false });
    }

    if (showRatingFilter) {
      dispatch({
        type: 'SET_SHOW_RATING_FILTER',
        payload: false
      });
    }

    if (showCombineSearch) {
      dispatch({ type: 'SET_SHOW_COMBINE_SEARCH', payload: false });
    }

    if (showFilter) {
      dispatch({ type: 'SET_SHOW_FILTERS', payload: false });
    }
  };

  const handleOutsiteClick = () => {
    setDisableSearchAddress(true);
    setInputValue(currentAddress?.property);
  };

  const handleSearchAddressChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleCurrentTab = (tab) => {
    dispatch({ type: 'SET_CURRENT_TAB', payload: tab });

    if (currentLifestyleType.length > 0) {
      dispatch({ type: 'RESET_CURRENT_LIFESTYLE_TYPE' });
    }
  };

  useEffect(() => {
    if (isGoogleMapsLoaded && !isLoading) {
      if (window.google && window.google.maps && inputRef.current) {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
          inputRef.current,
          {}
        );
        autoCompleteRef.current.addListener('place_changed', async () => {
          const place = await autoCompleteRef.current.getPlace();
          if (place && place.geometry) {
            addressClick(place.formatted_address);
          }
        });
      }
    }
  }, [isGoogleMapsLoaded, isLoading]);

  const addressClick = async (address) => {
    actions.sendDataToGoogleAnalytics('used_address_search', {});
    dispatch({ type: 'SET_LOADING', payload: true });
    setInputValue(address);
    setDisableSearchAddress(true);
    await actions.fetchAddressData(address);
  };

  useEffect(() => {
    if (currentAddress) {
      setInputValue(currentAddress.property);
    }
  }, [currentAddress]);

  useEffect(() => {
    if (!disableSearchAddress) {
      inputRef.current.focus();
    }
  }, [disableSearchAddress]);

  const enableSearchAddress = () => {
    setDisableSearchAddress(false);
    setInputValue('');
    handleMobileMapControls();
  };

  const openFeedbackForm = () => {
    dispatch({
      type: 'SET_SHOW_FEEDBACK_FORM',
      payload: true
    });
    handleMobileMapControls();
  };

  const isAddressSaved = () => {
    return savedAddresses.some(
      (address) => address.property === compareLifeStyleAddress[selectedMap]?.property
    );
  };

  const handleSaveAddressToFavorites = () => {
    saveAddressToFavorites(
      isAddressSaved() ? 'remove' : 'save',
      compareLifeStyleAddress[selectedMap],
      savedAddresses,
      dispatch,
      enqueueSnackbar,
    );
    // handleMobileMapControls();
  };

  const handleShareAddress = () => {
    shareAddress(enqueueSnackbar, compareLifeStyleAddress[selectedMap].property);
    handleMobileMapControls();
  };

  const handleComparePopup = () => {
    if (showFilter) {
      dispatch({ type: 'SET_SHOW_FILTERS', payload: false });
    }

    if (currentMarker[selectedMap]) {
      dispatch({
        type: 'SET_CURRENT_MARKER',
        payload: { markerIndex: 0, markerData: null },
      });
    }

    dispatch({
      type: 'SET_SHOW_COMPARE_POPUP',
      payload: !showComparePopup
    });
    // handleMobileMapControls();
  };

  const handleScoreGuide = () => {
    dispatch({ type: 'SET_SHOW_SCORE_GUIDE', payload: true });
    dispatch({ type: 'SET_SHOW_TUTORIAL', payload: true });
    handleMobileMapControls();
  };

  const handleMobileMapControls = () => {
    dispatch({
      type: 'SET_SHOW_MAP_MOBILE_CONTROLS',
      payload: !showMapMobileControls
    });
  };

  const renderMobileControlsPopup = () => {
    return (
      <div className={`mobile-controls-popup ${
        showMapMobileControls ? 'fade-in' : 'fade-out'
      }`}
      >
        <div className={`mobile-controls-card ${
            showMapMobileControls ? 'zoom-in' : 'zoom-out'}
          }`}
        >
          <Close
            className='mobile-controls-close-btn'
            onClick={() => handleMobileMapControls()}
          />
          <Show.When isTrue={!showCompareLifeStyle}>
            <div onClick={() => enableSearchAddress()}>Change address</div>
          </Show.When>
          <div onClick={() => openFeedbackForm()}>Add a comment</div>
          <Show.When isTrue={showDashboard}>
            <div onClick={() => handleSaveAddressToFavorites()}>
              Add to favorite
            </div>
          </Show.When>
          <Show.When
            isTrue={
              compareLifeStyleAddress[selectedMap] &&
              compareLifeStyleAddress[selectedMap].property !== undefined
            }>
            <div onClick={() => handleShareAddress()}>Share neighborhood</div>
          </Show.When>
          <Show.When isTrue={!showDashboard}>
            <div onClick={() => handleScoreGuide()}>Score Guide</div>
          </Show.When>
        </div>
      </div>
    )
  };

  const handleSearchAddressValue = () => {
    if (!searchAddressValue) {
      setSearchAddressValue(compareLifeStyleAddress[selectedMap]?.property);
    }
  };

  useEffect(() => {
    if (showCompareLifeStyle) {
      const property = compareLifeStyleAddress[selectedMap]?.property;
      let value = property ? property : '';
      setSearchAddressValue(value);
    }
  }, [showCompareLifeStyle]);

  useEffect(() => {
    const property = compareLifeStyleAddress[selectedMap]?.property;
    let value = property ? property : '';
    setSearchAddressValue(value);
  }, [selectedMap]);

  const handleAddressClick = (payload, index) => {
    if (showCompareLifeStyle) {
      dispatch({
        type: 'SET_COMPARE_MAPS_SLIDER_INDEX',
        payload: index
      });
      dispatch({
        type: 'UPDATE_LIFESTYLE_COMPARE_ADDRESS',
        payload: { item: index, address: payload },
      });
    } else {
      actions.fetchAddressData(payload.property);
      setInputValue(payload.property)
    }
  };

  const openCompareExit = () => {
    dispatch({ type: 'SET_SHOW_COMPARE_CONFIRM_EXIT', payload: true });
  };

  const handleMobileSidebar = () => {
    dispatch({ type: 'SET_MOBILE_SIDEBAR', payload: !isMobileSidebarOpen });

    if (showFilter) {
      dispatch({ type: 'SET_SHOW_FILTERS', payload: false });
    }

    if (currentMarker[selectedMap]) {
      dispatch({
        type: 'SET_CURRENT_MARKER',
        payload: { markerIndex: 0, markerData: null },
      });
    }
  };

  return (
    <>
      <div className='map-mobile-header-container'>
        <div className={`controls ${showCompareLifeStyle && 'compare-address'}`}>
          {/* <MobileBack
            className='back-btn'
            onClick={() => handleBack()}
          /> */}
          <Menu onClick={() => handleMobileSidebar()} />
          <Show>
            <Show.When isTrue={showCompareLifeStyle}>
              <Show>
                <Show.When isTrue={showCompareGraph}>
                  COMPARE ADDRESS
                </Show.When>
                <Show.Else>
                  <ClickOutsideHandler
                    className='mobile-address-input-wrapper'
                    onOutsideClick={handleSearchAddressValue}
                  >
                    <Autocomplete
                      // className='lifestyle-search-address-input'
                      placeholder={`Enter Address ${selectedMap + 1}`}
                      value={searchAddressValue}
                      index={selectedMap}
                      onClick={handleAddressClick}
                    />
                    <Show.When isTrue={!isObjectEmpty(compareLifeStyleAddress[selectedMap])}>
                      <Favorite
                        className={`add-to-favorites-icon ${
                          isAddressSaved() ? 'added-to-favorites' : ''
                        }`}
                        onClick={() => handleSaveAddressToFavorites()}
                      />
                    </Show.When>
                  </ClickOutsideHandler>
                </Show.Else>
              </Show>
              <Action
                className='clear-icon'
                onClick={() => openCompareExit()}
              />
            </Show.When>
            <Show.Else>
              <ClickOutsideHandler className='mobile-address-input-wrapper' onOutsideClick={handleOutsiteClick}>
                <Autocomplete
                  ref={inputRef}
                  type='text'
                  value={inputValue}
                  // disabled={disableSearchAddress}
                  onChange={(e) => handleSearchAddressChange(e)}
                  onClick={handleAddressClick}
                />
                <Show.When isTrue={!isObjectEmpty(compareLifeStyleAddress[selectedMap])}>
                  <Favorite
                    className={`add-to-favorites-icon ${
                      isAddressSaved() ? 'added-to-favorites' : ''
                    }`}
                    onClick={() => handleSaveAddressToFavorites()}
                  />
                </Show.When>
              </ClickOutsideHandler>
              {/* {!isAddress && (
                <Edit className='edit-btn' onClick={() => enableSearchAddress()} />
              )} */}

              <Action
                className={`action-btns  ${
                  isLoading ? 'btn-loading' : ''
                }`}
                onClick={isLoading ? null : () => handleComparePopup()}
              />
            </Show.Else>
          </Show>
        </div>
        {/* <Show.When isTrue={showCompareLifeStyle}>
          <div className='search-address-wrapper'>
            <ClickOutsideHandler
              className='search-address-och'
              onOutsideClick={handleSearchAddressValue}
            >
              <div className='address-num'>A{selectedMap + 1}</div>
              <Autocomplete
                className='lifestyle-search-address-input'
                placeholder={`Enter Address ${selectedMap + 1}`}
                value={searchAddressValue}
                index={selectedMap}
                onClick={handleAddressClick}
              />
              <Action
                className='clear-icon'
                onClick={() => handleClear()}
              />
            </ClickOutsideHandler>
          </div>
        </Show.When> */}
        {/* <Show.When isTrue={!isEstablishmentSearchOpen}>
          <div className={`mobile-switch-buttons ${
              hideFeel ? 'gem-only' : ''
            }`}
          >
            <button
              className={`${!isWalkIndex && 'selected'}`}
              onClick={() => handleCurrentTab('lifestyle')}
            >
              NEIGHBORHOOD GEMS
            </button>
            <button
              className={`${
                isWalkIndex && 'selected'
              } ${
                hideFeel ? 'hide-feel' : ''
              }`}
              onClick={() => handleCurrentTab('walkindex')}
            >
              NEIGHBORHOOD FEEL
            </button>
          </div>
        </Show.When> */}
      </div>
      <Show.When isTrue={showMapMobileControls}>
        {renderMobileControlsPopup()}
      </Show.When>
    </>
  )
};

export default MapMobileHeader;