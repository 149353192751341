import { useContext, version } from 'react';
import { StoreContext } from '../store';

import { ReactComponent as ModernGem } from '../assets/modern-gem.svg';
import { ReactComponent as ModernCircle } from '../assets/modern-eclipse.svg';
import { ReactComponent as Gem } from '../assets/address-score/gem2.svg';
import { ReactComponent as Circle } from '../assets/address-score/circle2.svg';
import { ReactComponent as Info } from '../assets/info-outline.svg';

import Show from './Show';

export const ScoreIcon = ({
  height='100px',
  width='auto',
  withBg=true,
  score='1',
  content=null,
  fontSize="20px",
  type,
}) => {
  const { state, dispatch, actions } = useContext(StoreContext);

  const { currentTab, showDashboard, isMobile } = state;

  const isWalkIndex = () => {
    if (type) {
      return type === 'walkindex';
    } else {
      return currentTab === 'walkindex';
    }
  };

  const styleWrapper = {
    '--icon-content-font-size': fontSize,
    '--icon-height': height,
    '--icon-width': width
  }

  return (
    <div
      className={`score-icon-container ${
        !withBg && 'white-icon'
      }`}
      style={styleWrapper}
    >
      <div className={`score-content ${
          !isWalkIndex() && 'gem-content'
        } ${
          !isWalkIndex() &&
          !isMobile &&
          version === 1
          ? 'desktop-gem-content'
          : ''
        }`}
      >
        {content}
      </div>
      <Show>
        <Show.When isTrue={withBg}>
          <Show>
            <Show.When isTrue={isWalkIndex()}>
              <ModernCircle className='score-icon' />
            </Show.When>
            <Show.Else>
              <ModernGem className='score-icon' />
            </Show.Else>
          </Show>
          <div
            className='score-progress'
            data-score-value={score}
          >
            <Show>
              <Show.When isTrue={isWalkIndex()}>
                <ModernCircle className='score-icon' />
              </Show.When>
              <Show.Else>
                <ModernGem className='score-icon' />
              </Show.Else>
            </Show>
          </div>
        </Show.When>
        <Show.Else>
          <div
            className='white-score-progress'
            data-score-value={withBg ? null : score}
          >
            <Show>
              <Show.When isTrue={isWalkIndex()}>
                <ModernCircle className='score-icon' />
              </Show.When>
              <Show.Else>
                <ModernGem className='score-icon' />
              </Show.Else>
            </Show>
          </div>
        </Show.Else>
      </Show>
    </div>
  )
};

export const ScoreIconV2 = ({
  height='auto',
  width='70px',
  isWalkIndex,
  className="",
  content,
  showDesc=false,
  index=0,
  score='1',
  fontSize="24px",
}) => {
  const getIcon = (iconClass) => {
    const appliedClass = iconClass ? iconClass : '';
    if (isWalkIndex) {
        return <Circle className={appliedClass} data-score-value={score} />;
    } else {
        return <Gem className={appliedClass} data-score-value={score} />;
    }
  };

  const legend = [
    { label: 'Meh', value: 1 },
    { label: 'Yeah', value: 2 },
    { label: 'Wow', value: 3 },
  ];

  const styleWrapper = {
    '--icon-content-font-size': fontSize,
    '--icon-height': height,
    '--icon-width': width
  }

  return (
    <div className={`score-icon-v2 ${
        !isWalkIndex ? 'gem' : ''
      } ${className}`}
      style={styleWrapper}
    >
      <div
      >
        {getIcon()}
        {getIcon('overlay-icon')}
        <span className='shadow-lg font-medium'>{content}</span>
        <Show.When isTrue={showDesc}>
          <div className='description text-center mt-2 text-sm'> { legend[index].label || 'Wow'} </div> 
        </Show.When>
      </div>
    </div>
  )
}