import { ReactComponent as PlayIcon } from '../../assets/play-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/cancel-white.svg';
import { ReactComponent as Burger } from '../../assets/burger-menu.svg';
import { ReactComponent as Logo } from '../../assets/walkspan-logo-white-2.svg';

import Show from '../../utility/Show';
import ClickOutsideHandler from '../../utility/ClickOutsideHandler';

const MobileSidebar = ({ state, dispatch, actions }) => {
  const {
    showTutorial,
    isMobileSidebarOpen,
    isDarkMode,
    isMobile,
    showCompareLifeStyle,
    showComparePopup,
    showRecentHistoy,
    showFavoriteList,
    showMapQS,
    showFilter,
  } = state;

  const handleTutorial = () => {
    dispatch({ type: 'SET_SHOW_TUTORIAL', payload: !showTutorial });
    closeSidebar()
  };

  const closeSidebar = () => {
    dispatch({ type: 'SET_MOBILE_SIDEBAR', payload: false });
  };

  const openFavorites = () => {
    dispatch({ type: 'SET_SHOW_FAVORITE_LIST', payload: true });
    closeSidebar();
    if (showRecentHistoy && isMobile) {
      dispatch({ type: 'SET_SHOW_RECENT_HISTORY', payload: false });
    }
  };

  const openRecentHistory = () => {
    dispatch({ type: 'SET_SHOW_RECENT_HISTORY', payload: true });
    closeSidebar();

    if (showFavoriteList && isMobile) {
      dispatch({ type: 'SET_SHOW_FAVORITE_LIST', payload: false });
    }
  };

  const openComparePopup = () => {
    if (isMobile) {
      dispatch({ type: 'SET_SHOW_COMPARE_POPUP', payload: true });
    } else {
      dispatch({ type: 'SET_COMPARE_LIFESTYLE', payload: true });
      if (showFavoriteList) {
        dispatch({ type: 'SET_SHOW_FAVORITE_LIST', payload: false });
      }
      if (showRecentHistoy) {
        dispatch({ type: 'SET_SHOW_RECENT_HISTORY', payload: false });
      }
    }
    dispatch({ type: 'SET_PREVIOUS_PAGE', payload: 2 });

    closeSidebar();
  };

  const handleTheme = () => {
    dispatch({ type: 'SET_IS_DARK_MODE', payload: !isDarkMode });
  };

  return (
    <div className={`sidebar-v2 ${
        isMobileSidebarOpen ? 'open' : ''
      } ${
        !isDarkMode ? 'light' : ''
      }`}
    >
      <ClickOutsideHandler className='content' onOutsideClick={closeSidebar}>
        <div className='buttons-wrapper'>
          <div className='header-btns'>
            <Show>
              <Show.When isTrue={isMobile}>
                <div className='btn view-tutorial' onClick={() => handleTutorial()}>
                  <PlayIcon /> View Tutorial
                </div>
                <CloseIcon className='close-icon' onClick={() => closeSidebar()} />
              </Show.When>
              <Show.Else>
                Welcome, User!
                <Burger className='close-icon' onClick={() => closeSidebar()} />
              </Show.Else>
            </Show>
          </div>
          <div className='features'>
            <Show.When isTrue={!isMobile}>
              <div
                className='feature-btn'
                onClick={() => handleTutorial()}
              >
                View Tutorial
              </div>
            </Show.When>
            <div
              className='feature-btn'
              onClick={() => openFavorites()}
            >
              My Favorites
            </div>
            <div
              className='feature-btn'
              onClick={() => openRecentHistory()}
            >
              Recent History
            </div>
            <Show.When isTrue={!showCompareLifeStyle && !showComparePopup}>
              <div
                className='feature-btn'
                onClick={() => openComparePopup()}
              >
                Compare address
              </div>
            </Show.When>

            {/* <div className='feature-btn toggle-theme-wrapper'>
              <span>Dark mode</span>
              <div
                className='toggle-button'
                onClick={
                  actions.areMapsLoading() ? null : () => handleTheme()
                }
              >
                <input type='checkbox' checked={isDarkMode} readOnly />
                <span
                  className={`theme-slider`}
                ></span>
              </div>
            </div> */}
            {/* <Show.When isTrue={!isMobile}>
              <div
                className='feature-btn'
                onClick={() => openFavorites()}
              >
                Help / Support
              </div>
            </Show.When> */}
            {/* <div
              className='feature-btn'
              onClick={() => actions.handleLogout()}
            >
              Log out
            </div> */}
          </div>
        </div>
        <div className='powered-by'>
          powered by <Logo className='logo' />
        </div>
      </ClickOutsideHandler>
    </div>
  )
};

export default MobileSidebar;